import React from 'react'
import { classes, style } from 'typestyle'
import { WidgetBasicFullHeightStyles, WidgetStyles } from './styles'
import WidgetTags from './WidgetTags'
import { openLinkInNewTab, trackWidgetEvent } from '../Widgets'
import { Widget } from '../../../types'
import { prefillUrlWithUserInfo } from '../../../libs/user-utils'

const BasicWidget = ({
  tags,
  header,
  description,
  mediaPath,
  mediaStyle,
  link,
  linkParamsFn,
  isFullHeight
}: Widget & { isFullHeight: boolean }) => {
  return (
    <div
      className={classes(
        'basic-widget-container',
        WidgetStyles.containerStyle,
        WidgetStyles.hoverTransformStyle,
        isFullHeight && WidgetBasicFullHeightStyles.containerStyle
      )}
      onClick={() => {
        trackWidgetEvent(header)
        openLinkInNewTab(linkParamsFn ? prefillUrlWithUserInfo(link, linkParamsFn) : link)
      }}
    >
      <img src={mediaPath} alt='Widget' className={classes(WidgetStyles.baseImageStyle, style(mediaStyle))} />
      <div className={WidgetStyles.infoContainerStyle}>
        <WidgetTags tags={tags} />
        <div className={classes('basic-widget-info', WidgetStyles.infoStyle)}>
          <div className={classes('basic-widget-header', WidgetStyles.headerTextStyle)}>{header}</div>
          {description && (
            <div className={classes('basic-widget-description', WidgetStyles.descriptionTextStyle)}>{description}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BasicWidget
