import { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'

const _sessionId = genSessionId(8)

export const sessionIDInterceptor = _sessionId

function genSessionId(length = 8): string {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export function sessionIdInterceptor(config: AxiosRequestConfig): InternalAxiosRequestConfig {
  if (!config.headers) {
    config.headers = {}
  }

  config.headers['TraceAir-Session-Id'] = sessionIDInterceptor

  return config as InternalAxiosRequestConfig
}
