import * as React from 'react'
import { useState } from 'react'
import { classes, media, style } from 'typestyle'
import { Blue80, Button, Gray100, Gray140, typeCompact24Display, White } from '@traceair/tds'
import useAppStore, { AppStoreState } from '../../AppStore'
import { MAIN_CONTAINER_MAX_WIDTH } from '../../layout-constants'
import Widgets from './Widgets'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import track from '../../Tracking'
import { Breakpoint } from '../../responsive-breakpoints'
import { VisibilityOffIcon, VisibilityOnIcon } from '@traceair/webapp-icons'

const WidgetsContainer = () => {
  const [showWidgets, setShowWidgets] = useState(true)
  const user = useAppStore((state: AppStoreState) => state.user)
  const greetingText = user ? `${t7e(i18nKeys.Welcome)}, ${user.name} ${user.lastname}!` : `${t7e(i18nKeys.Welcome)}!`

  return (
    <div className={classes('dashboard-widgets-container', containerStyle)}>
      <div className={classes('dashboard-widgets-top', topStyle)}>
        <div className={classes('dashboard-widgets-header', typeCompact24Display, headerStyle)}>{greetingText}</div>
        <Button
          onClick={() => {
            track(showWidgets ? 'Hide widgets Click' : 'Show widgets Click')
            setShowWidgets(!showWidgets)
          }}
          className={classes('dashboard-widgets-collapse-button', WidgetsButtonStyle)}
          text='Widgets'
          icon={showWidgets ? <VisibilityOnIcon color={Blue80} /> : <VisibilityOffIcon color={Gray100} />}
          type='black'
        />
      </div>
      {showWidgets && (
        <div className={classes('dashboard-widgets-wrapper', widgetsStyle)}>
          <Widgets />
        </div>
      )}
    </div>
  )
}

export default WidgetsContainer

const containerStyle = style(
  {
    boxSizing: 'border-box',
    color: White,
    padding: '0 80px',
    borderTop: `1px solid ${Gray140}`
  },
  media({ maxWidth: Breakpoint.TABLET }, { padding: '0px' }),
  media({ maxWidth: Breakpoint.MOBILE }, { padding: '0px' })
)

const topStyle = style(
  {
    alignItems: 'flex-end',
    display: 'flex',
    minHeight: 48,
    justifyContent: 'space-between',
    maxWidth: MAIN_CONTAINER_MAX_WIDTH,
    margin: '24px auto'
  },
  media({ maxWidth: Breakpoint.TABLET }, { marginTop: 40, marginBottom: 32 }),
  media({ maxWidth: Breakpoint.MOBILE }, { marginTop: 48 })
)

const headerStyle = style(
  {
    fontSize: 40,
    fontWeight: 500,
    lineHeight: 1.2
  },
  media({ maxWidth: Breakpoint.TABLET }, { marginLeft: 40 }),
  media({ maxWidth: Breakpoint.MOBILE }, { fontSize: 32, marginLeft: 24 })
)

const widgetsStyle = style({
  maxWidth: MAIN_CONTAINER_MAX_WIDTH,
  margin: '0 auto'
})

const WidgetsButtonStyle = style(
  {
    marginRight: 0
  },
  media({ maxWidth: Breakpoint.MOBILE }, { marginRight: 24 }),
  media({ maxWidth: Breakpoint.TABLET }, { marginRight: 40 })
)
