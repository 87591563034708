import { useEffect, useRef } from 'react'
import { classes, media, style } from 'typestyle'
import Sidebar from '../sidebar/Sidebar'
import { Gray10, White } from '@traceair/tds'
import SitesContainer from './SitesContainer'
import { MAIN_CONTAINER_MAX_WIDTH, SIDEBAR_WIDTH } from '../../layout-constants'
import HelpButton from '../HelpButton'
import { Breakpoint, hideOnTabletsOrMobiles } from '../../responsive-breakpoints'
import { useWindowWidth } from '../../hooks'
import useAppStore, { AppStoreType } from '../../AppStore'
import { useShallow } from 'zustand/react/shallow'
import { MapModeContainer } from '../sites_map/MapModeContainer'

const { enabled: intercomEnabled } = INTERCOM

const MainContainer = () => {
  const windowWidth = useWindowWidth()
  const isTabletOrMobile = windowWidth <= Breakpoint.TABLET

  const { isSidebarOpen, actions, viewMode } = useAppStore(
    useShallow((state: AppStoreType) => ({
      isSidebarOpen: state.isSidebarOpen,
      actions: state.actions,
      viewMode: state.viewMode
    }))
  )
  const { setIsSidebarOpen } = actions

  const isSidebarOpenOnDesktop = useRef(true)

  useEffect(() => {
    if (isTabletOrMobile) {
      setIsSidebarOpen(false)
    } else {
      setIsSidebarOpen(isSidebarOpenOnDesktop.current)
    }
  }, [isTabletOrMobile])

  const handleSidebarVisibilityChange = (value: boolean) => {
    setIsSidebarOpen(value)

    if (!isTabletOrMobile) {
      isSidebarOpenOnDesktop.current = value
    }
  }

  if (viewMode === 'MAP') {
    return <MapModeContainer sidebarOpen={isSidebarOpen} onVisibilityChanged={handleSidebarVisibilityChange} />
  }

  return (
    <div className={classes('dashboard-main-container', containerStyle, !isSidebarOpen && containerCollapsedMenuStyle)}>
      <div
        className={classes(
          'dashboard-layout-container',
          layoutStyle,
          !isSidebarOpen && sidebarCollapsedStyle,
          !isSidebarOpen && hideOnTabletsOrMobiles
        )}
      >
        <Sidebar onVisibilityChanged={handleSidebarVisibilityChange} isCollapsed={!isSidebarOpen} />
        <SitesContainer
          isSidebarCollapsed={!isSidebarOpen}
          onSidebarVisibilityChanged={handleSidebarVisibilityChange}
        />
        {intercomEnabled && (
          <HelpButton className={classes(helpButtonStyle, hideOnTabletsOrMobiles)} iconColor='black' />
        )}
      </div>
    </div>
  )
}

export default MainContainer

const containerStyle = style(
  {
    background: White,
    borderRadius: '32px 32px 0 0',
    flexGrow: 1,
    padding: '30px 80px 48px'
  },
  media(
    { maxWidth: Breakpoint.TABLET },
    {
      paddingRight: 40,
      paddingLeft: 40
    }
  ),
  media(
    { maxWidth: Breakpoint.MOBILE },
    {
      paddingRight: 24,
      paddingLeft: 24
    }
  )
)

const containerCollapsedMenuStyle = style(
  {
    padding: '30px 80px 48px 0'
  },
  media(
    { maxWidth: Breakpoint.TABLET },
    {
      paddingRight: 40,
      paddingLeft: 40
    }
  ),
  media(
    { maxWidth: Breakpoint.MOBILE },
    {
      paddingRight: 24,
      paddingLeft: 24
    }
  )
)

const layoutStyle = style(
  {
    alignItems: 'start',
    display: 'grid',
    gridTemplateColumns: `1fr ${SIDEBAR_WIDTH + 80}px minmax(auto, ${
      MAIN_CONTAINER_MAX_WIDTH - SIDEBAR_WIDTH - 80
    }px) 1fr`,
    justifyContent: 'center',
    maxWidth: '100%'
  },
  media(
    { maxWidth: Breakpoint.TABLET },
    {
      gridTemplateColumns: 'none'
    }
  )
)

const sidebarCollapsedStyle = style({
  gridTemplateColumns: `1fr minmax(auto, ${MAIN_CONTAINER_MAX_WIDTH}px) 1fr`
})

const helpButtonStyle = style({
  backgroundColor: Gray10,
  position: 'fixed',
  bottom: 16,
  right: 16
})
