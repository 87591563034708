import { classes, stylesheet } from 'typestyle'
import { typeMono14MediumProperties, typeMono17MediumProperties, WhiteOpacity40, Gray170 } from '@traceair/tds'
import { Marker } from 'react-map-gl/maplibre'
import { useAnimationMarker } from '../layers/markers_layer/useAnimationMarker'
import { Position } from 'geojson'
import { Z_INDICES } from '../constants'

interface ClusterProps {
  count: number
  onClick: () => void
  toCoordinates: Position
  fromCoordinates?: Position
}

const ITEMS_IN_CLUSTER_MAP = {
  S: 5,
  L: 15
}

enum SIZE_ICON {
  S = 'small',
  M = 'medium',
  L = 'large'
}

function ClusterMarker({ count, onClick, fromCoordinates, toCoordinates }: ClusterProps) {
  const size = getClusterSize(count)

  const { coordinates } = useAnimationMarker(toCoordinates, fromCoordinates)

  return (
    <Marker
      {...coordinates}
      style={{
        zIndex: Z_INDICES.CLUSTER_MARKER
      }}
    >
      <div onClick={onClick} className={classes('map-marker-cluster', css.clusterIcon, IconSizeStyle[size])}>
        {count}
      </div>
    </Marker>
  )
}

const getClusterSize = (pointCount: number) => {
  if (pointCount < ITEMS_IN_CLUSTER_MAP.S) {
    return SIZE_ICON.S
  }

  if (pointCount > ITEMS_IN_CLUSTER_MAP.L) {
    return SIZE_ICON.L
  }

  return SIZE_ICON.M
}

const css = stylesheet({
  clusterIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    outline: `8px solid ${WhiteOpacity40}`,
    borderRadius: '50%',
    cursor: 'pointer',
    border: '0 solid white',
    transition: 'border-width 150ms ease-in-out',
    color: Gray170,
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(16, 24, 41, 0.06), 0px 1px 3px 0px rgba(16, 24, 41, 0.10)',
    $nest: {
      '&:hover': {
        borderWidth: '8px'
      }
    }
  }
})

const ICON_SIZE_S = '40px'
const ICON_SIZE_M = '64px'
const ICON_SIZE_L = '88px'

export const IconSizeStyle = stylesheet({
  [SIZE_ICON.S]: {
    ...typeMono14MediumProperties,
    width: ICON_SIZE_S,
    height: ICON_SIZE_S
  },
  [SIZE_ICON.M]: {
    ...typeMono14MediumProperties,
    width: ICON_SIZE_M,
    height: ICON_SIZE_M
  },
  [SIZE_ICON.L]: {
    ...typeMono17MediumProperties,
    width: ICON_SIZE_L,
    height: ICON_SIZE_L
  }
})

export default ClusterMarker
