import { classes, style } from 'typestyle'
import { Toolbar, ToolbarItem, Tooltip } from '@traceair/tds'
import useAppStore from '../../AppStore'
import { CardViewIcon, EarthIcon } from '@traceair/webapp-icons'
import { i18nKeys } from '../../i18n/i18nkeys'
import { t7e } from '../../i18n/i18n'
import { trackMapMode } from '../../Tracking'

const SitesViewSwitcher = () => {
  const viewMode = useAppStore(state => state.viewMode)
  const setViewMode = useAppStore(state => state.actions.setViewMode)

  return (
    <div className={classes('sites-view-switcher', containerStyle)}>
      <Toolbar size='s' theme={viewMode === 'GRID' ? 'light' : 'dark'}>
        <Tooltip description={t7e(i18nKeys.ModeSwitcherCardView)} placement='bottom'>
          <ToolbarItem
            onClick={() => {
              setViewMode('GRID')
              trackMapMode('switch to GRID')
            }}
            icon={CardViewIcon}
            selected={viewMode === 'GRID'}
          />
        </Tooltip>
        <Tooltip description={t7e(i18nKeys.ModeSwitcherMapView)} placement='bottom'>
          <ToolbarItem
            onClick={() => {
              setViewMode('MAP')
              trackMapMode('switch to MAP')
            }}
            icon={EarthIcon}
            selected={viewMode === 'MAP'}
          />
        </Tooltip>
      </Toolbar>
    </div>
  )
}

export default SitesViewSwitcher

const containerStyle = style({
  flex: '1 0 auto'
})
