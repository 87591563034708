import SiteCardBadges from '../../../sites_grid/site_card/SiteCardBadges'
import { Badge } from '@traceair/tds'
import { classes, stylesheet } from 'typestyle'
import { buildScanPreviewUrl } from '../../../sites_grid/site_card/helpers'
import NoScanStub from '../../../../noscan.png'
import { SiteMapData } from '../../stores/MapStore'
import { useState, memo } from 'react'
import { JWTToken } from '../../../../jwt-utils'
import { getShortFormattedDataLatestScan } from './helpers'

type SiteCardPreviewProps = {
  siteData: SiteMapData
  siteViewToken: JWTToken | undefined
}

const SiteCardPreview = memo(function SiteCardPreview({ siteData, siteViewToken }: SiteCardPreviewProps) {
  const { hasLotViewer, latestScan, schedule } = siteData
  const scanPreviewPath = latestScan?.previewPath
  const hasScanPreview = scanPreviewPath && siteViewToken
  const [imgSrc, setImgSrc] = useState(
    hasScanPreview ? buildScanPreviewUrl(scanPreviewPath, siteViewToken) : NoScanStub
  )

  const formattedDateLatestScan = getShortFormattedDataLatestScan(siteData?.latestScan)

  const latestProgress =
    Number(schedule?.latestProgressSnapshot.progress) >= 0 ? `${schedule?.latestProgressSnapshot.progress} %` : ''

  const siteCardBadgesClassName = classes(css.badges, latestProgress && css.badgesWithSchedule)

  return (
    <div className={css.scanPreviewContainer}>
      <div className={css.badgesContainer}>
        <SiteCardBadges
          formattedDate={formattedDateLatestScan}
          isLotViewerEnabled={hasLotViewer}
          lotViewerLabel='LV'
          className={siteCardBadgesClassName}
        />
        {latestProgress ? <Badge type='informative' label={latestProgress} /> : null}
      </div>
      <img
        onError={() => setImgSrc(NoScanStub)}
        crossOrigin='anonymous'
        className={css.scanPreviewImage}
        src={imgSrc}
        alt='Scan preview'
      />
    </div>
  )
})

const css = stylesheet({
  scanPreviewContainer: {
    width: '120px',
    height: '80px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  badgesContainer: {
    position: 'absolute',
    display: 'flex',
    top: 4,
    left: 4,
    gap: 2,
    zIndex: 1
  },
  badges: {
    display: 'flex',
    gap: 2
  },
  scanPreviewImage: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  badgesWithSchedule: {
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
})

export default SiteCardPreview
