import React from 'react'
import { ButtonIcon } from '@traceair/tds'
import { ChatIcon } from '@traceair/webapp-icons'
import { classes, style } from 'typestyle'
import track from '../Tracking'

interface IHelpButtonProps {
  className?: string
  iconColor?: 'white' | 'black'
}

const HelpButton: React.FC<IHelpButtonProps> = ({ className, iconColor }) => {
  const showIntercomWindow = () => {
    window.Intercom('update', {
      hide_default_launcher: true
    })
    window.Intercom('show')
  }

  return (
    <ButtonIcon
      icon={ChatIcon}
      className={classes(btnStyle, className)}
      type={iconColor}
      style={iconColor === 'black' ? 'secondary' : 'tertiary'}
      onClick={() => {
        track('Click on Help button')
        showIntercomWindow()
      }}
    />
  )
}

export default HelpButton

const btnStyle = style({
  borderRadius: 12,
  height: 40,
  width: 40
})
