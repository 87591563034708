import axios, { AxiosResponse } from 'axios'
import { sessionIdInterceptor } from './SessionIDInterceptor'
import { CustomerData, Filters, ProjectActivity, SiteData, Sorting } from '../types'
import { tokenInterceptor } from './TokenInterceptor'

const CustomerDashboardAPIService = axios.create({
  baseURL: CustomerDashboardAPIUrl
})

CustomerDashboardAPIService.interceptors.request.use(config => sessionIdInterceptor(config))
CustomerDashboardAPIService.interceptors.request.use(config => tokenInterceptor(config))

export type RequestedSite = {
  customerId: string
  siteId: string
  name: string
  projectActivity: ProjectActivity
}

const prepareRequestedSites = (customers: CustomerData[]): RequestedSite[] =>
  customers.flatMap(customer =>
    customer.sites.map(site => ({
      siteId: site.id,
      customerId: customer.id,
      name: site.name,
      projectActivity: site.projectActivity
    }))
  )

export async function loadSitesList() {
  const response: AxiosResponse<{ customers: CustomerData[] }> = await CustomerDashboardAPIService.get('/user-sites')

  return response.data.customers
}

type LoadSitesDataResponse = {
  sites: SiteData[]
  totalSites: number
}

export async function loadSitesData(
  customers: CustomerData[],
  chunkParams: {
    count: number
    skip: number
  },
  filters: Filters,
  sorting: Sorting,
  siteNameFilterString: string,
  abortSignal: AbortSignal
) {
  const sites = prepareRequestedSites(customers)
  const response: AxiosResponse<{ dashboardData: LoadSitesDataResponse }> = await CustomerDashboardAPIService.post(
    '/dashboard-data',
    {
      sites,
      count: chunkParams.count,
      skip: chunkParams.skip,
      filter: filters,
      sort: sorting,
      searchBySiteName: siteNameFilterString
    },
    {
      signal: abortSignal
    }
  )

  return response.data.dashboardData
}

export async function saveFilters(email: string, filter: Filters, sort: Sorting) {
  const response: AxiosResponse = await CustomerDashboardAPIService.post(`/users/${email}/filter-settings`, {
    filter,
    sort
  })

  return response.data
}

type LoadFiltersResponse = {
  filter: Filters
  sort: Sorting
}

export async function getFilters(email: string) {
  const response: AxiosResponse<{ filterSettings: LoadFiltersResponse | null }> = await CustomerDashboardAPIService.get(
    `/users/${email}/filter-settings`
  )

  return response.data.filterSettings
}

export type StatisticRequestData = {
  siteId: string
  customerId: string
  projectActivity: ProjectActivity
}

export type StatisticResponseData = {
  customerId: string
  withPastDueMilestones: number
  withLotViewer: number
  withSchedules: number
  totalSites: number
}

export async function loadStatistic(sites: StatisticRequestData[], filter: Filters): Promise<StatisticResponseData[]> {
  const response: AxiosResponse<{ statistic: StatisticResponseData[] }> = await CustomerDashboardAPIService.post(
    '/statistics',
    { sites, filter }
  )

  return response.data.statistic
}
