import useMapStore from '../../stores/MapStore'
import { useShallow } from 'zustand/react/shallow'
import { BaseLayerMode } from '../../../../types'
import { classes, stylesheet } from 'typestyle'
import { typeCompact12MediumProperties, White } from '@traceair/tds'
import DefaultView from './default.jpg'
import SatelliteView from './satellite.jpg'
import { t7e } from '../../../../i18n/i18n'
import { i18nKeys } from '../../../../i18n/i18nkeys'
import { Z_INDICES } from '../../constants'
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_WIDTH } from '../../../../layout-constants'
import { trackMapMode } from '../../../../Tracking'

const getSwitcherTitle = (baseLayerMode?: BaseLayerMode) => {
  switch (baseLayerMode) {
    case BaseLayerMode.Satellite: {
      return t7e(i18nKeys.BaseLayerSwitcherSatellite)
    }

    case BaseLayerMode.OSM: {
      return t7e(i18nKeys.BaseLayerSwitcherDefault)
    }

    default:
      return ''
  }
}

type BaseLayerModeSwitcherProps = {
  isSidebarCollapsed: boolean
}

export function BaseLayerModeSwitcher({ isSidebarCollapsed }: BaseLayerModeSwitcherProps) {
  const { baseLayerMode, setBaseLayerMode } = useMapStore(
    useShallow(state => ({
      baseLayerMode: state.baseLayerMode,
      setBaseLayerMode: state.actions.setBaseLayerMode
    }))
  )
  const switcherTitle = getSwitcherTitle(baseLayerMode)

  const handleClick = () => {
    if (baseLayerMode === BaseLayerMode.Satellite) {
      setBaseLayerMode(BaseLayerMode.OSM)
      trackMapMode('switch to OSM')
    } else {
      setBaseLayerMode(BaseLayerMode.Satellite)
      trackMapMode('switch to Satellite')
    }
  }

  const imgBaseLayerMode = baseLayerMode === BaseLayerMode.Satellite ? SatelliteView : DefaultView

  return (
    <div
      className={classes(css.switcherContainer, isSidebarCollapsed && css.switcherContainerCollapsedMenu)}
      onClick={handleClick}
    >
      <img src={imgBaseLayerMode} alt='view mode' className={css.imgBaseLayer} width='96' height='80' />
      <span>{switcherTitle}</span>
    </div>
  )
}

const SWITCHER_GAP = 24

const css = stylesheet({
  switcherContainer: {
    ...typeCompact12MediumProperties,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'end',

    borderRadius: 12,
    backgroundColor: White,

    position: 'absolute',
    left: `calc(${SIDEBAR_WIDTH}px + ${SWITCHER_GAP}px)`,

    width: 96,
    height: 94,

    padding: 4,

    cursor: 'pointer',

    zIndex: Z_INDICES.BASE_LAYER_SWITCHER
  },
  switcherContainerCollapsedMenu: {
    left: `calc(${SIDEBAR_COLLAPSED_WIDTH}px + ${SWITCHER_GAP}px)`
  },
  imgBaseLayer: {
    width: '100%',
    height: '100%',

    borderRadius: 8,

    paddingBottom: 3
  }
})
