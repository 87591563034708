import { MAPBOX_SATELLITE_TILES_URL, OSM_TILES_URLS } from '../constants'

export class BaseLayerManager {
  static layerSourceId = 'BaseLayer'
  private static OSMId = `${BaseLayerManager.layerSourceId}:osm`
  private static MapboxId = `${BaseLayerManager.layerSourceId}:mapbox`

  getSatelliteLayer() {
    return {
      id: BaseLayerManager.MapboxId,
      source: BaseLayerManager.MapboxId
    }
  }

  getSatelliteSource() {
    return {
      id: BaseLayerManager.MapboxId,
      maxzoom: 17,
      tiles: [MAPBOX_SATELLITE_TILES_URL],
      tileSize: 512,
      attribution:
        '© <a href="https://www.mapbox.com/about/maps/" target="_blank">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
    }
  }

  getOSMSource() {
    return {
      id: BaseLayerManager.OSMId,
      maxzoom: 19,
      tiles: OSM_TILES_URLS,
      tileSize: 256,
      attribution: '© <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors'
    }
  }

  getOSMLayer() {
    return {
      id: BaseLayerManager.OSMId,
      source: BaseLayerManager.OSMId
    }
  }
}

const baseLayerManager = new BaseLayerManager()

export default baseLayerManager
