import { INavigationBarButtonProps } from '@traceair/tds'
import { OfficeAppLogoIcon } from '@traceair/webapp-icons'
import track from '../../../../Tracking'

export default function createOfficeAppButton(): INavigationBarButtonProps | null {
  if (!OfficeUrl) {
    return null
  }

  return {
    icon: OfficeAppLogoIcon,
    text: 'Office',
    onClick: () => {
      track('Office Button Click')
      window.open(OfficeUrl, '_blank')
    }
  }
}
