import { Button, ButtonIcon, Tooltip } from '@traceair/tds'
import { t7e } from '../../../../i18n/i18n'
import { i18nKeys } from '../../../../i18n/i18nkeys'
import { CopyLinkIcon, MoreIcon, OpenInNewIcon } from '@traceair/webapp-icons'
import { stylesheet } from 'typestyle'
import { buildOfficeLink } from '../../../../helpers'
import { CustomerData, SiteData } from '../../../../types'
import { FC, useRef } from 'react'
import ContextMenu from '../../../sites_grid/site_card/context_menu/ContextMenu'
import { useContextMenu } from '../../../sites_grid/site_card/context_menu/useContextMenu'
import { trackMapMode } from '../../../../Tracking'

type SiteCardMapFooterProps = {
  site: SiteData
  customer: CustomerData
}

const SiteCardMapFooter: FC<SiteCardMapFooterProps> = ({ site, customer }) => {
  const contextMenuRef = useRef<HTMLDivElement>(null)
  const officeAppLink = buildOfficeLink(site.customerId, site)

  const { isContextMenuOpen, toggleContextMenu } = useContextMenu(contextMenuRef)

  const handleOpenNewTab = () => {
    trackMapMode('"Open site in new tab" clicked')
    window.open(officeAppLink, '_blank')
  }

  const handleCopyLink = () => {
    trackMapMode('link to Office copied')
    navigator.clipboard.writeText(officeAppLink)
  }

  return (
    <div className={css.footer}>
      <Button
        text={t7e(i18nKeys.SiteCardMapOpenTab)}
        icon={OpenInNewIcon}
        onClick={handleOpenNewTab}
        iconPosition='right'
      />
      <Tooltip description={t7e(i18nKeys.SiteCardMapCopyLink)}>
        <ButtonIcon icon={CopyLinkIcon} type='black' style='secondary' onClick={handleCopyLink} />
      </Tooltip>
      <div ref={contextMenuRef}>
        <Tooltip description={t7e(i18nKeys.SiteCardMapActions)}>
          <ButtonIcon icon={MoreIcon} type='black' style='secondary' onClick={toggleContextMenu} />
        </Tooltip>
        {isContextMenuOpen && <ContextMenu site={site} customer={customer} className={css.contextMenu} />}
      </div>
    </div>
  )
}

export default SiteCardMapFooter

const css = stylesheet({
  footer: {
    padding: '12px 12px 0 12px',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    position: 'relative'
  },
  contextMenu: {
    bottom: 48,
    top: 'auto',
    right: 12
  }
})
