import React from 'react'
import { classes, style } from 'typestyle'
import { White } from '@traceair/tds'
import { WIDGET_CARD_HEIGHT, WIDGET_CARD_WIDTH, WidgetStyles } from './styles'
import WidgetTags from './WidgetTags'
import { openLinkInNewTab, trackWidgetEvent } from '../Widgets'
import PlayIcon from '../../../icons/PlayIcon'
import { Widget } from '../../../types'
import { prefillUrlWithUserInfo } from '../../../libs/user-utils'

const VideoWidget = ({
  tags,
  header,
  description,
  mediaPath,
  showPlayerIcon,
  mediaStyle,
  link,
  linkParamsFn,
  videoPoster
}: Widget) => {
  return (
    <div
      className={classes('video-widget-container', WidgetStyles.containerStyle, WidgetStyles.hoverTransformStyle)}
      onClick={() => {
        trackWidgetEvent(header)
        openLinkInNewTab(linkParamsFn ? prefillUrlWithUserInfo(link, linkParamsFn) : link)
      }}
    >
      {showPlayerIcon && (
        <div className={classes('video-widget-play-icon', videoIconStyle)}>
          <PlayIcon />
        </div>
      )}
      <video
        src={mediaPath}
        autoPlay
        loop
        muted
        className={classes(videoStyle, style(mediaStyle))}
        poster={videoPoster}
      />

      {description && <BodyWithDescription tags={tags} header={header} description={description} />}
      {!description && <BodyWithoutDescription tags={tags} header={header} />}
    </div>
  )
}

const BodyWithDescription = ({ tags, header, description }: Partial<Widget>) => {
  if (!tags) return null

  return (
    <div className={classes('video-widget-info-container', WidgetStyles.infoContainerStyle)}>
      <WidgetTags tags={tags} />
      <div className={classes('video-widget-info', WidgetStyles.infoStyle)}>
        <div className={classes('video-widget-header', WidgetStyles.headerTextStyle)}>{header}</div>
        <div className={classes('video-widget-description', WidgetStyles.descriptionTextStyle)}>{description}</div>
      </div>
    </div>
  )
}

const BodyWithoutDescription = ({ tags, header }: Partial<Widget>) => {
  if (!tags) return null

  return (
    <div
      className={classes(
        'video-widget-info-container',
        style({
          marginLeft: 16,
          height: '100%',
          zIndex: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        })
      )}
    >
      <div className={classes('video-widget-info', videoCardInfoContainer)}>
        <div
          className={classes(
            'video-widget-header',
            WidgetStyles.headerTextStyle,
            style({
              maxWidth: 235
            })
          )}
        >
          {header}
        </div>
      </div>
      <WidgetTags
        tags={tags}
        containerPositionStyle={style({
          marginBottom: 20
        })}
      />
    </div>
  )
}

export default VideoWidget

const videoCardInfoContainer = style({
  marginTop: 16,
  zIndex: 3,
  color: White
})

const videoIconStyle = style({ position: 'absolute', top: 16, right: 16, zIndex: 3 })
const videoStyle = style({
  width: WIDGET_CARD_WIDTH,
  height: WIDGET_CARD_HEIGHT,
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  filter: 'brightness(0.7)'
})
