import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { BaseLayerMode, SiteData } from '../../../types'
import { LngLat } from 'maplibre-gl'
import { Position } from 'geojson'
import { MapGeoJSONFeature } from 'react-map-gl/maplibre'

export type Cluster = {
  pointCount: number
  id: number
  coordinates: Position
  expansionZoom: number
  center: LngLat
  children: number[]
  parentCoordinates?: Position
}

export type Point = {
  coordinates: Position
  properties: MapGeoJSONFeature['properties']
}

export type SiteMapData = Omit<SiteData, 'id'> & {
  coordinates: [number, number] | null
  customerName: string
  uniqueId: string
}

export type SitesDataByUniqueId = Record<SiteMapData['uniqueId'], SiteMapData>

export type LocationCoordinates = { longitude: number; latitude: number } | null

type State = {
  zoomDelta: number | null

  baseLayerMode: BaseLayerMode

  points: Point[]
  clusters: Cluster[]

  sitesDataByUniqueId: SitesDataByUniqueId
  selectedSiteUniqueId: string | null

  needsFitToSites: boolean

  locationCoordinates: LocationCoordinates
  locationIsEnabled: boolean
  locationNeedToFly: boolean
  locationError: boolean
}

type Actions = {
  actions: {
    setPoints: (points: Point[]) => void
    setClusters: (clusters: Cluster[]) => void

    setZoomDelta: (zoomDelta: number) => void
    clearZoomDelta: () => void

    setSitesDataByUniqueId: (sitesDataByUniqueId: SitesDataByUniqueId) => void
    setSelectedSiteUniqueId: (selectedSiteUniqueId: string | null) => void
    clearSelectedSiteUniqueId: () => void

    setNeedsFitToSites: (value: boolean) => void

    setBaseLayerMode: (baseLayerMode: BaseLayerMode) => void

    setLocationCoordinates: (locationCoordinates: LocationCoordinates) => void
    setLocationIsEnabled: (enable: boolean) => void
    setLocationNeedToFly: (locationNeedToFly: boolean) => void
    setLocationError: (error: boolean) => void
  }
}

export type MapStoreType = State & Actions

const useMapStore = create<MapStoreType>()(
  subscribeWithSelector(set => ({
    sitesDataByUniqueId: {},
    selectedSiteUniqueId: null,
    baseLayerMode: BaseLayerMode.OSM,
    zoomDelta: null,
    points: [],
    clusters: [],
    sitesIdCoordinates: {},

    needsFitToSites: false,

    locationCoordinates: null,
    locationIsEnabled: false,
    locationError: false,
    locationNeedToFly: true,

    actions: {
      setZoomDelta: (zoomDelta: number) => {
        set(() => ({ zoomDelta }))
      },
      clearZoomDelta: () => {
        set(() => ({ zoomDelta: null }))
      },

      setPoints: points => {
        set(() => ({ points }))
      },
      setClusters: clusters => {
        set(() => ({ clusters }))
      },

      setSitesDataByUniqueId: (sitesDataByUniqueId: SitesDataByUniqueId) => set({ sitesDataByUniqueId }),
      setSelectedSiteUniqueId: selectedSiteUniqueId => {
        set(() => ({ selectedSiteUniqueId }))
      },
      clearSelectedSiteUniqueId: () => {
        set(() => ({ selectedSiteUniqueId: null }))
      },

      setNeedsFitToSites: (value: boolean) => set({ needsFitToSites: value }),

      setBaseLayerMode: (baseLayerMode: BaseLayerMode) => set({ baseLayerMode }),

      setLocationCoordinates: locationCoordinates => {
        set(() => ({ locationCoordinates }))
      },
      setLocationIsEnabled: locationIsEnabled => {
        set(() => ({ locationIsEnabled }))
      },
      setLocationNeedToFly: locationNeedToFly => {
        set(() => ({ locationNeedToFly }))
      },
      setLocationError: locationError => {
        set(() => ({ locationError }))
      }
    }
  }))
)

export default useMapStore
