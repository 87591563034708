import { RefObject, useState, MouseEvent } from 'react'
import track from '../../../../Tracking'
import { useOnClickOutside } from '../../../../hooks'

export const useContextMenu = (contextMenuRef: RefObject<HTMLDivElement>) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const toggleContextMenu = (e: MouseEvent) => {
    track('Context menu button Click')
    e.stopPropagation()
    setIsContextMenuOpen(prev => !prev)
  }

  const closeContextMenu = () => {
    setIsContextMenuOpen(false)
  }

  useOnClickOutside(contextMenuRef, closeContextMenu)

  return { isContextMenuOpen, toggleContextMenu }
}
