import * as React from 'react'
import { classes, style } from 'typestyle'
import { windowBackdropStyle } from '@traceair/tds'
import { ReactNode, useEffect } from 'react'

type WindowManagerProps = {
  className?: string
  window: ReactNode
}

const WindowManager: React.FunctionComponent<WindowManagerProps> = ({
  className: windowClassName,
  window
}: WindowManagerProps) => {
  // Prevent scrolling of content underneath when the modal window is shown
  useEffect(() => {
    const initialOverflow = document.body.style.overflow

    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = initialOverflow
    }
  }, [])

  return (
    <div className={classes(dialogStyle)}>
      <div className={classes('modal-backdrop', backdropStyle, windowBackdropStyle)} />
      <div className={classes('window-container', windowContainerStyle, windowClassName)}>{window}</div>
    </div>
  )
}

const dialogStyle = style({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1000,
  pointerEvents: 'none'
})

const backdropStyle = style({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 1000,
  pointerEvents: 'all'
})

const windowContainerStyle = style({
  position: 'relative',
  width: 'auto',
  zIndex: 1000,
  pointerEvents: 'all',
  display: 'flex',
  maxHeight: '100%'
})

export default WindowManager
