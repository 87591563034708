import { Point } from '@influxdata/influxdb-client-browser'
// forked from https://github.com/boynet/Influxdb-js-client
// don't want another dependency + need jwt authorization

// This implementation is identical to the one in Osnova.
// Before making changes here, please review Osnova code.
// https://github.com/TraceAir/osnova/pull/2576/files

export class InfluxDB {
  sendPointsOnClose: boolean
  host: string
  points: Point[]
  beaconSent: boolean

  constructor(host: string, sendPointsOnClose = false) {
    this.sendPointsOnClose = sendPointsOnClose
    this.host = host
    this.points = []
    this.beaconSent = false
    if (this.sendPointsOnClose) this.registerUnloadEvent()

    return this
  }

  registerUnloadEvent() {
    // need both events to work in chrome http://stackoverflow.com/a/20322988/1368683
    window.addEventListener('unload', this.sendBeacon, false)
    window.onbeforeunload = this.sendBeacon
  }

  private sendBeacon = () => {
    // need this polyfill https://github.com/miguelmota/Navigator.sendBeacon
    if (this.beaconSent) return
    if (this.points && this.points.length === 0) return
    this.beaconSent = true
    if (!navigator || !navigator.sendBeacon) return
    const data = this.implodePoints()

    navigator.sendBeacon(this.host, data)
  }

  point(point: Point) {
    return this.addPoint(point)
  }

  private addPoint(point: Point): InfluxDB {
    this.points.push(point)

    return this
  }

  private implodePoints() {
    if (this.points.length === 0) return ''
    let data = ''

    for (let index = 0; index < this.points.length; ++index) {
      data = data + this.points[index].toLineProtocol()
    }

    return data
  }

  send(accessToken: string) {
    if (this.points.length === 0) return false
    const data = this.implodePoints()

    if (data) {
      const request = new XMLHttpRequest()

      request.open('POST', this.host, true)
      request.setRequestHeader('Content-Type', 'text/plain; charset=UTF-8')
      request.setRequestHeader('Authorization', `Bearer ${accessToken}`)
      request.send(data)
    }

    this.points = []
  }
}
