import { classes, stylesheet } from 'typestyle'
import SiteCardBadges from './SiteCardBadges'
import { t7e } from '../../../i18n/i18n'
import { i18nKeys } from '../../../i18n/i18nkeys'
import SitePreviewImg from './SitePreviewImg'
import SiteCardTitle from './SiteCardTitle'
import SchedulesSummary from './schedule/SchedulesSummary'
import { buildOfficeLink } from '../../../helpers'
import { getFormattedDateLatestScan } from './helpers'
import { CustomerData, SiteData } from '../../../types'
import { Gray20 } from '@traceair/tds'

type SiteCardContentProps = {
  site: SiteData
  customer: CustomerData
  scanPreviewClassName?: string
}

function SiteCardContent({ site, customer, scanPreviewClassName }: SiteCardContentProps) {
  const { name, hasLotViewer, schedule, latestScan, latestPanos } = site
  const officeAppLink = buildOfficeLink(customer.id, site)

  const isMilestonesSelected = schedule?.milestones.some(milestone => milestone.isKey)
  const formattedDateLatestScan = getFormattedDateLatestScan(latestScan)

  return (
    <>
      <div className={classes('scan-preview-badges-container', css.siteCardBadgesContainer)}>
        <SiteCardBadges
          isLotViewerEnabled={hasLotViewer}
          formattedDate={formattedDateLatestScan}
          lotViewerLabel={t7e(i18nKeys.LotViewer)}
          className={css.siteCardBadges}
        />
      </div>
      <SitePreviewImg
        scan={latestScan}
        panoramas={latestPanos}
        officeAppLink={officeAppLink}
        scanPreviewClassName={scanPreviewClassName}
      />
      <SiteCardTitle siteName={name} customerName={customer.name} schedule={schedule} officeAppLink={officeAppLink} />
      {schedule?.latestProgressSnapshot.milestones && isMilestonesSelected && <SiteCardSpacer />}
      {schedule && <SchedulesSummary schedule={schedule} />}
    </>
  )
}

const SiteCardSpacer = () => <div className={classes('site-card-spacer', css.siteCardSpacer)} />

const css = stylesheet({
  siteCardSpacer: {
    height: 2,
    width: '100%',
    backgroundColor: Gray20,
    margin: '12px 0 12px 0'
  },
  siteCardBadgesContainer: {
    position: 'absolute',
    top: 8,
    left: 8,
    padding: 8,
    gap: 8,
    display: 'flex',
    zIndex: 1 // make badges visible over an image
  },
  siteCardBadges: {
    gap: 8,
    display: 'flex'
  }
})

export default SiteCardContent
