import { INavigationBarButtonProps } from '@traceair/tds'
import { ProjectsAppLogoIcon } from '@traceair/webapp-icons'
import { checkIfCanWorkInProjectsManagementApp } from '../../../../utils'
import { IAcl } from '../../../../jwt-utils'
import track from '../../../../Tracking'

export default function createProjectsManagementAppButton(acl: IAcl): INavigationBarButtonProps | null {
  const canWorkInProjectsManagementApp = checkIfCanWorkInProjectsManagementApp(acl.systemLevel)

  if (!canWorkInProjectsManagementApp || !ProjectsManagementAppUrl) {
    return null
  }

  return {
    icon: ProjectsAppLogoIcon,
    text: 'Projects',
    onClick: () => {
      track('Projects Button Click')
      window.open(ProjectsManagementAppUrl, '_blank')
    }
  }
}
