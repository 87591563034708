import React from 'react'
import { ButtonIcon } from '@traceair/tds'
import { classes, style } from 'typestyle'
import { ChevronLeftIcon, ChevronRightIcon } from '@traceair/webapp-icons'
import { hideOnTabletsOrMobiles } from '../../../../responsive-breakpoints'

const BUTTON_WIDTH = 24
const BUTTON_HEIGHT = 24

interface IArrowButtonProps {
  className?: string
  onClick: () => void
  direction: 'left' | 'right'
  visible?: boolean
}

const ArrowButtonComponent: React.FC<IArrowButtonProps> = ({ className, onClick, direction, visible }) => {
  return (
    <ButtonIcon
      onClick={e => {
        e && e.stopPropagation()
        onClick()
      }}
      icon={direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      size='xs'
      className={classes(
        className,
        arrowButtonStyle(!!visible),
        hideOnTabletsOrMobiles,
        direction === 'left' ? leftStyle : rightStyle
      )}
      type='white'
    />
  )
}

const ArrowButton = React.memo(ArrowButtonComponent)

export default ArrowButton

const arrowButtonStyle = (visible: boolean) =>
  style({
    width: BUTTON_WIDTH,
    height: BUTTON_HEIGHT,
    opacity: visible ? 1 : 0,
    top: '50%',
    position: 'absolute',
    zIndex: 1,
    $nest: {
      '&:after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        zIndex: -1,
        transform: 'scale(1.5)'
      }
    }
  })

const leftStyle = style({
  left: 8,
  right: 'unset'
})

const rightStyle = style({
  right: 8,
  left: 'unset'
})
