import { Layer, Source } from 'react-map-gl/maplibre'
import baseLayerManager from './BaseLayerManager'
import { BaseLayerMode } from '../../../../types'
import useMapStore from '../../stores/MapStore'

function BaseLayer() {
  const baseLayerMode = useMapStore(state => state.baseLayerMode)

  // it's intentionally several `Source` and `Layer` because every `baseLayerMode` should be a separate component.
  // 'Source' and 'Layout' can't update dynamically, and the `sourceId` must remain constant.

  return (
    <>
      {baseLayerMode === BaseLayerMode.Satellite && (
        <Source type='raster' {...baseLayerManager.getSatelliteSource()}>
          <Layer type='raster' {...baseLayerManager.getSatelliteLayer()} />
        </Source>
      )}
      {baseLayerMode === BaseLayerMode.OSM && (
        <Source type='raster' {...baseLayerManager.getOSMSource()}>
          <Layer type='raster' {...baseLayerManager.getOSMLayer()} />
        </Source>
      )}
    </>
  )
}

export default BaseLayer
