import React, { useRef } from 'react'
import { classes, media, style } from 'typestyle'
import { BlackOpacity60, Blue80, ButtonIcon, White } from '@traceair/tds'
import { MoreIcon } from '@traceair/webapp-icons'
import ContextMenu from './ContextMenu'
import { CustomerData, SiteData } from '../../../../types'
import { Breakpoint } from '../../../../responsive-breakpoints'
import { useContextMenu } from './useContextMenu'

interface ContextMenuButtonProps {
  customer: CustomerData
  site: SiteData
}

const ContextMenuButton: React.FC<ContextMenuButtonProps> = ({ customer, site }) => {
  const contextMenuRef = useRef<HTMLDivElement>(null)

  const { isContextMenuOpen, toggleContextMenu } = useContextMenu(contextMenuRef)

  return (
    <div
      className={style({
        position: 'relative'
      })}
      ref={contextMenuRef}
    >
      <ButtonIcon
        className={classes('site-card-context-menu-button', contextMenuButtonStyle(isContextMenuOpen))}
        size='xs'
        icon={<MoreIcon color={White} />}
        onClick={toggleContextMenu}
      />
      {isContextMenuOpen && <ContextMenu site={site} customer={customer} />}
    </div>
  )
}

export default ContextMenuButton

const contextMenuButtonStyle = (isActive: boolean) =>
  style(
    {
      background: isActive ? Blue80 : BlackOpacity60,
      width: '36px',
      height: '36px',
      position: 'absolute',
      borderRadius: '12px',
      top: 16,
      right: 16,
      display: isActive ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      zIndex: 1,
      backdropFilter: 'blur(12.5px)'
    },
    media(
      { maxWidth: Breakpoint.TABLET },
      {
        display: 'flex'
      }
    )
  )
