import { formatDistanceToNowStrict } from 'date-fns'
import { getDFNSLocale, t7e } from '../../../i18n/i18n'
import { i18nKeys } from '../../../i18n/i18nkeys'
import { Scan } from '../../../types'
import { JWTToken } from '../../../jwt-utils'
import urljoin from 'url-join'

export const getFormattedDateLatestScan = (latestScan?: Scan) =>
  latestScan
    ? formatDistanceToNowStrict(new Date(latestScan.doneDate), { addSuffix: true, locale: getDFNSLocale() })
    : t7e(i18nKeys.NoScan)

export const buildScanPreviewUrl = (previewPath: string, token: JWTToken) => {
  return urljoin(FOStorage.url, FOStorage.filesPathPrefix, previewPath, `?access_token=${token}`)
}
