import { i18nKeys, literals } from './i18nkeys'

const translation: literals = {
  [i18nKeys.Welcome]: 'Welcome',
  [i18nKeys.Project]: 'Project',
  [i18nKeys.Projects]: 'Projects',
  [i18nKeys.AllProjects]: 'All Projects',
  [i18nKeys.Backoffice]: 'Backoffice',
  [i18nKeys.Profile]: 'Profile',
  [i18nKeys.Menu]: 'Menu',

  [i18nKeys.SearchProject]: 'Search sites',
  [i18nKeys.Sites]: 'sites',
  [i18nKeys.SitesFound]: 'sites found',
  [i18nKeys.NoSitesFound]: 'No Sites Found',
  [i18nKeys.AddNewProject]: 'Add\xa0new project by clicking “+\xa0New” button',

  // Filter Dictionary
  [i18nKeys.Total]: 'Total',
  [i18nKeys.Active]: 'Active',
  [i18nKeys.WithSchedules]: 'With Schedules',
  [i18nKeys.WithLotViewer]: 'With Lot Viewer',
  [i18nKeys.PastDueMilestones]: 'Past Due Milestones',
  [i18nKeys.Archive]: 'Archived',

  [i18nKeys.ActiveProjectState]: 'Active',
  [i18nKeys.ArchiveProjectState]: 'Archive',

  [i18nKeys.AddProject]: 'Add project',

  [i18nKeys.PickKeyMilestones]: 'Pick up to 3 milestones to display',

  [i18nKeys.Options]: 'Options',
  [i18nKeys.NoScan]: 'No scans',
  [i18nKeys.LotViewer]: 'Lot Viewer',

  [i18nKeys.Pano]: 'Panorama',
  [i18nKeys.Panos]: 'Panoramas',
  [i18nKeys.RequestPano]: 'Add Panoramas',
  [i18nKeys.LastPano]: (date: string) => `Last Panorama ${date}`,
  [i18nKeys.LastPanos]: (date: string) => `Last Panoramas ${date}`,

  [i18nKeys.ArchiveProject]: 'Archive project',
  [i18nKeys.UnarchieveProject]: 'Unarchive project',

  [i18nKeys.UserAdminWindowTitle]: (siteName: string) => `${siteName}s Users`,
  [i18nKeys.UserAdminButtonTitle]: 'Manage Users',

  [i18nKeys.ManageProjects]: 'Projects',

  // Sort by Dictionary
  [i18nKeys.SortBy]: 'Sort by',
  [i18nKeys.SiteName]: 'Site name',
  [i18nKeys.ScanDate]: 'Scan date',
  [i18nKeys.ScheduleUpdated]: 'Schedule update',
  [i18nKeys.ScheduleProgress]: 'Schedule progress',
  [i18nKeys.SortByNameDirect]: 'A - Z',
  [i18nKeys.SortByNameReverse]: 'Z - A',
  [i18nKeys.SortByTimeDirect]: 'Newest - Oldest',
  [i18nKeys.SortByTimeReverse]: 'Oldest - Newest',
  [i18nKeys.SortByProgressDirect]: 'Completed - Uncompleted',
  [i18nKeys.SortByProgressReverse]: 'Uncompleted - Completed',
  [i18nKeys.StatisticBlockTitleLotViewer]: 'WITH LOT VIEWER',
  [i18nKeys.StatisticBlockTitleSchedule]: 'WITH SCHEDULES',
  [i18nKeys.StatisticBlockAddMore]: 'Add more',

  // Context Menu Dictionary
  [i18nKeys.New]: 'New',
  [i18nKeys.AddLotViewer]: 'Add Lot Viewer',
  [i18nKeys.AddSchedules]: 'Add Schedules',
  [i18nKeys.RequestNewScan]: 'Request new flights',

  // Upsells texts
  [i18nKeys.ButtonCancel]: 'Cancel',
  [i18nKeys.ButtonClose]: 'Close',

  [i18nKeys.UpsellPopupLotViewerTitle]: 'Add Lot Viewer',
  [i18nKeys.UpsellPopupLotViewerDescription]: 'Check cut/fill balance and elevation information for each pad or lot.',

  [i18nKeys.UpsellPopupPanosTitle]: 'Add panoramas',
  [i18nKeys.UpsellPopupPanosCountField]: 'Panoramas per scan',

  [i18nKeys.UpsellPopupSchedulesTitle]: 'Add Schedules',
  [i18nKeys.UpsellPopupSchedulesDescription]: 'Correlate your schedules with on-site progress.',

  [i18nKeys.UpsellPopupFlightsTitle]: 'Add flights',
  [i18nKeys.UpsellPopupFlightsCountField]: 'Number of flights',

  [i18nKeys.UpsellPopupGuideLink]: 'How it works',

  [i18nKeys.UpsellPopupFieldPlaceholderAccount]: 'Select account',
  [i18nKeys.UpsellPopupFieldLabelAccount]: 'Account name',

  [i18nKeys.UpsellPopupFieldPlaceholderSite]: 'Select site',
  [i18nKeys.UpsellPopupFieldLabelSite]: 'Site name',

  [i18nKeys.UpsellPopupFieldPlaceholderComment]: 'Comment (optional)',

  [i18nKeys.UpsellPopupNetworkError]: 'Request failed, please try again later',
  [i18nKeys.UpsellPopupSuccess]: 'We received your request',

  // UserProfileForm Dictionary
  [i18nKeys.ProfileForm]: 'Your profile',
  [i18nKeys.ProfileFormEmail]: 'Email',
  [i18nKeys.ProfileFormName]: 'Name',
  [i18nKeys.ProfileFormLastName]: 'Last Name',
  [i18nKeys.ProfileFormPhone]: 'Phone',
  [i18nKeys.ProfileFormPosition]: 'Position',
  [i18nKeys.ProfileFormChangePassword]: 'Change Password',
  [i18nKeys.ProfileFormSave]: 'Save changes',
  [i18nKeys.ProfileFormLogout]: 'Sign out',
  [i18nKeys.ProfileFormChangesSaved]: 'Changes saved',
  [i18nKeys.TermsOfUse]: 'Our Terms of Use',
  [i18nKeys.Subscriptions]: 'Email Subscriptions',

  // Position Dictionary
  [i18nKeys.EXECUTIVE_DIRECTOR_VP]: 'Executive/Director/VP',
  [i18nKeys.PROJECT_MANAGER]: 'Project Manager',
  [i18nKeys.SENIOR_PROJECT_MANAGER]: 'Senior Project Manager',
  [i18nKeys.LAND_DEVELOPMENT_MANAGER]: 'Land Development Manager',
  [i18nKeys.CONSTRUCTION_MANAGER]: 'Construction Manager',
  [i18nKeys.ENVIRONMENTAL]: 'Environmental',
  [i18nKeys.SWPPP_MANAGER]: 'SWPPP/Environmental Manager',
  [i18nKeys.ASSISTANT_MANAGER]: 'Assistant Manager',
  [i18nKeys.PURCHASING_AGENT_MANAGER]: 'Purchasing Agent/Manager',
  [i18nKeys.OTHER]: 'Other',
  [i18nKeys.EXECUTIVE_PRINCIPAL]: 'Executive/Principal',
  [i18nKeys.PROJECT_ENGINEER]: 'Project Engineer',
  [i18nKeys.ESTIMATOR]: 'Estimator',
  [i18nKeys.SUPERINTENDENT]: 'Superintendent',
  [i18nKeys.FOREMAN]: 'Foreman',
  [i18nKeys.GRADE_SETTER]: 'Grade Setter',
  [i18nKeys.SENIOR_ENGINEER]: 'Senior Engineer',
  [i18nKeys.ASSOCIATE_ENGINEER]: 'Associate Engineer',
  [i18nKeys.GEOLOGIST]: 'Geologist',
  [i18nKeys.GEOTECHNICAL_ENGINEER]: 'Geotechnical Engineer',
  [i18nKeys.HYDROLOGIST]: 'Hydrologist',
  [i18nKeys.CONSULTANT]: 'Consultant',
  [i18nKeys.ARCHITECT_URBAN_PLANNER]: 'Architect/Urban Planner',
  [i18nKeys.PILOT]: 'Pilot',
  [i18nKeys.SURVEYOR]: 'Surveyor',
  [i18nKeys.SWPPP]: 'SWPPP',
  [i18nKeys.ENVIRONMENTAL_CONSULTANT]: 'Environmental Consultant',
  [i18nKeys.CHIEF]: 'Chief',
  [i18nKeys.HEAD]: 'Head',
  [i18nKeys.MANAGER]: 'Manager',
  [i18nKeys.EXPERT]: 'Expert',
  [i18nKeys.SPECIALIST]: 'Specialist',
  [i18nKeys.ENGINEER]: 'Engineer',
  [i18nKeys.DRONE_PILOT]: 'Pilot',

  [i18nKeys.CurrentPassword]: 'Current password',
  [i18nKeys.NewPassHint]: 'Create your new password',
  [i18nKeys.NewPassLengthError]: 'Minimum 8 characters',
  [i18nKeys.NewPassReEnterHint]: 'Re-enter your new password',
  [i18nKeys.SavePassword]: 'Save new password',
  [i18nKeys.ChangesSaved]: 'Changes saved',

  [i18nKeys.NewPassDoNotMatchError]: 'Passwords do not match',
  [i18nKeys.NewPassRequiredError]: 'Enter new password',
  [i18nKeys.NoEnteredNewPassError]: 'Enter your new password twice',
  [i18nKeys.CurrentPassRequired]: 'Enter the current password',
  [i18nKeys.WrongEnteredCurrentPassError]: 'Current password is incorrect',

  // Site card in map mode
  [i18nKeys.SiteCardMapOpenTab]: 'Open in new tab',
  [i18nKeys.SiteCardMapCopyLink]: 'Copy link',
  [i18nKeys.SiteCardMapActions]: 'Site action',
  [i18nKeys.SiteCardDeselect]: 'Deselect site',

  // Mode switcher
  [i18nKeys.ModeSwitcherCardView]: 'Card view',
  [i18nKeys.ModeSwitcherMapView]: 'Map view',

  // Base layer mode switcher
  [i18nKeys.BaseLayerSwitcherSatellite]: 'Satellite',
  [i18nKeys.BaseLayerSwitcherDefault]: 'Default',

  // location errors
  [i18nKeys.PermissionsError]:
    'TraceAir does not have permission to access your location.\n\nPlease allow access and refresh the page. You may need to modify the privacy settings for your browser.',
  [i18nKeys.LocationError]: 'Geolocation failed.\nTry to enable GPS on your device, and refresh the page'
}

export default translation
