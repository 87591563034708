import { classes, stylesheet } from 'typestyle'
import { CustomerData, SiteData } from '../../../../types'
import SiteCardMapFooter from './SiteCardMapFooter'
import { Blue80, ButtonIcon, Gray20, Tooltip, White } from '@traceair/tds'
import { CloseIcon } from '@traceair/webapp-icons'
import { t7e } from '../../../../i18n/i18n'
import { i18nKeys } from '../../../../i18n/i18nkeys'
import SiteCardContent from '../../../sites_grid/site_card/SiteCardContent'
import useAppStore from '../../../../AppStore'
import { Z_INDICES } from '../../constants'

type SelectedSiteCardOnMapProps = {
  site: SiteData
  onClose: () => void
}

function SelectedSiteCardOnMap({ site, onClose }: SelectedSiteCardOnMapProps) {
  const customers = useAppStore(state => state.customers)

  const customer = customers.find((c: CustomerData) => c.id === site.customerId)

  if (!customer) {
    return null
  }

  return (
    <div className={classes('map-site-expanded-card', css.siteCardMapContainer)}>
      <div className={css.siteCard}>
        <Tooltip placement='bottom' description={t7e(i18nKeys.SiteCardDeselect)}>
          <ButtonIcon type='white' icon={CloseIcon} size='xs' className={css.menuButton} onClick={onClose} />
        </Tooltip>
        <SiteCardContent site={site} customer={customer} scanPreviewClassName={css.scanPreview} />
        <SiteCardMapFooter site={site} customer={customer} />
      </div>
    </div>
  )
}

const css = stylesheet({
  siteCardMapContainer: {
    position: 'absolute',
    right: 64,
    top: 72,
    maxWidth: 296,

    zIndex: Z_INDICES.EXPANDED_CARD
  },
  scanPreview: {
    height: '213px'
  },
  menuButton: {
    width: '36px',
    height: '36px',
    position: 'absolute',
    borderRadius: '12px',
    top: 16,
    right: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1,
    backdropFilter: 'blur(12.5px)'
  },
  siteCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    background: White,
    borderRadius: 16,
    maxWidth: 480,
    paddingBottom: 16,
    justifyContent: 'flex-end',
    border: `1px solid ${Gray20}`,
    boxShadow:
      '0px 1.751734972000122px 3.130388021469116px 0px rgba(0, 0, 0, 0.04), 0px 9px 25px 0px rgba(0, 0, 0, 0.07)',

    $nest: {
      '&:hover': {
        border: `1px solid ${Blue80}`,
        boxShadow:
          '0px 1.751734972000122px 3.130388021469116px 0px rgba(0, 122, 255, 0.1), 0px 9px 25px 0px rgba(0, 122, 255, 0.1)'
      },
      '&:hover .site-card-context-menu-button': {
        display: 'flex'
      }
    }
  }
})

export default SelectedSiteCardOnMap
