import React from 'react'
import { classes, style } from 'typestyle'
import { ButtonIcon } from '@traceair/tds'
import { CloseIcon } from '@traceair/webapp-icons'

interface IWindowCloseButtonProps {
  onClick: () => void
  className?: string
}

const WindowCloseButton = ({ onClick, className }: IWindowCloseButtonProps) => {
  return (
    <ButtonIcon
      size='xs'
      onClick={onClick}
      icon={CloseIcon}
      className={classes('icon-button', iconButtonStyle, className)}
    />
  )
}

const iconButtonStyle = style({
  $nest: {
    '&&': {
      background: 'none',
      padding: 0
    }
  }
})

export default WindowCloseButton
