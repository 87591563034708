import useAppStore from '../../../AppStore'
import { useShallow } from 'zustand/react/shallow'
import { Alert, Loader } from '@traceair/tds'
import { style } from 'typestyle'
import { useEffect, useState } from 'react'

export function MapAlerts() {
  const sitesDataLoading = useAppStore(useShallow(state => state.sitesDataLoading))

  const [toShowLoadingAlert, setToShowLoadingAlert] = useState(false)

  useEffect(() => {
    let timeoutId: number | undefined

    if (sitesDataLoading) {
      timeoutId = window.setTimeout(() => {
        setToShowLoadingAlert(true)
      }, 1000)
    } else {
      setToShowLoadingAlert(false)

      if (timeoutId !== undefined) {
        window.clearTimeout(timeoutId)
      }
    }

    return () => {
      if (timeoutId !== undefined) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [sitesDataLoading])

  return (
    <div>
      {toShowLoadingAlert && (
        <Alert
          icon={<Loader color='white' />}
          className={alertStyle}
          type='neutral-high-accent'
          title={'Sites are loading...'}
        />
      )}
    </div>
  )
}

const alertStyle = style({
  minWidth: 200
})
