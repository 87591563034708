import useAppStore from '../../../../AppStore'
import useMapStore, { SitesDataByUniqueId } from '../../stores/MapStore'
import { getFeatureCollection, toPointGeoJSON } from '../../../../libs/GeoJSONUtils'
import { Markers } from './MarkersLayer'
import { shallow } from 'zustand/shallow'

function makeUniqueId(customerId: string, siteId: string) {
  return `${customerId}__${siteId}`
}

export class MarkersLayerDataManager {
  private unsubscribeFromAppStoreSitesChanges?: () => void

  constructor() {}

  subscribeToStore() {
    this.unsubscribeFromAppStoreSitesChanges = useAppStore.subscribe(
      ({ sites }) => sites,
      this.handleAppStoreSitesChange,
      {
        equalityFn: shallow,
        fireImmediately: true // to fire on every switch to Map mode
      }
    )
  }

  unsubscribeFromStore() {
    if (this.unsubscribeFromAppStoreSitesChanges) {
      this.unsubscribeFromAppStoreSitesChanges()
    }
  }

  handleAppStoreSitesChange = () => {
    const totalSites = useAppStore.getState().totalSites
    const sites = useAppStore.getState().sites

    // if all sites are loaded, this logic is in SitesDataManager
    if (totalSites !== null && sites.length >= totalSites) {
      this.setSitesDataByUniqueId()
    }
  }

  setSitesDataByUniqueId() {
    const sitesData = useAppStore.getState().sites
    const allAvailableCustomersAndSites = useAppStore.getState().customers

    const allAvailableSitesWithCoords = allAvailableCustomersAndSites
      .flatMap(customer =>
        customer.sites.map(site => {
          const uniqueId = makeUniqueId(customer.id, site.id)

          return {
            [uniqueId]: {
              coordinates: site.coordinates,
              customerName: customer.name
            }
          }
        })
      )
      .reduce((acc, site) => {
        Object.assign(acc, site)

        return acc
      }, {})

    const sitesDataByUniqueId: SitesDataByUniqueId = {}

    sitesData.reduce((acc, site) => {
      const uniqueId = makeUniqueId(site.customerId, site.siteId)

      const siteWithCoordsByUniqueId = allAvailableSitesWithCoords[uniqueId]

      if (!siteWithCoordsByUniqueId) {
        return acc
      }

      const { coordinates, customerName } = siteWithCoordsByUniqueId

      acc[uniqueId] = { ...site, uniqueId, coordinates, customerName }

      return acc
    }, sitesDataByUniqueId)

    useMapStore.getState().actions.setSitesDataByUniqueId(sitesDataByUniqueId)
    useMapStore.getState().actions.setNeedsFitToSites(true)
  }

  getMarkers() {
    return {
      points: useMapStore.getState().points,
      clusters: useMapStore.getState().clusters
    }
  }

  setMarkers(markers: Markers) {
    useMapStore.getState().actions.setPoints(markers.points)
    useMapStore.getState().actions.setClusters(markers.clusters)
  }

  getFeatureCollectionSites() {
    const sitesDataByUniqueId = useMapStore.getState().sitesDataByUniqueId
    const sites = Object.values(sitesDataByUniqueId).map(({ coordinates, uniqueId }) => ({ coordinates, uniqueId }))

    const features = Object.values(sites)?.map(({ coordinates, uniqueId }) => {
      return toPointGeoJSON(coordinates![0], coordinates![1], uniqueId)
    })

    return getFeatureCollection(features)
  }
}

const markersLayerDataManager = new MarkersLayerDataManager()

export default markersLayerDataManager
