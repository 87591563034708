import React from 'react'
import track from '../../Tracking'
import { media, style } from 'typestyle'
import { EmailIcon } from '@traceair/webapp-icons'
import useAppStore from '../../AppStore'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import { Breakpoint } from '../../responsive-breakpoints'
import { Button } from '@traceair/tds'

const SubscriptionManagementAppButton: React.FunctionComponent = () => {
  const handleWindowEventReceived = (event: MessageEvent) => {
    if (event.data === 'SUBSCRIPTION_APP_WINDOW_CLOSE') {
      track('Subscription App window closed')
      useAppStore.getState().actions.hideWindow()
      window.removeEventListener('message', handleWindowEventReceived)
    }
  }

  function handleClick() {
    track('Subscription App button clicked')
    useAppStore.getState().actions.showWindow(
      <div className={SubscriptionWindowStyle}>
        <iframe
          style={{ border: 'none' }}
          width='100%'
          height='100%'
          src={`${SubscriptionAppUrl}/?embeddedMode=true`}
        />
      </div>
    )
    window.addEventListener('message', handleWindowEventReceived)
  }

  return <Button text={t7e(i18nKeys.Subscriptions)} icon={EmailIcon} onClick={handleClick} type='white' />
}

const SubscriptionWindowStyle = style(
  {
    display: 'flex',
    width: '60vw',
    height: '600px',
    maxWidth: '336px'
  },
  media(
    { maxWidth: Breakpoint.MOBILE },
    {
      width: 336,
      minHeight: '50%'
    }
  )
)

export default SubscriptionManagementAppButton
