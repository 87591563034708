import { classes, media, style } from 'typestyle'
import { White, typeCompact29DisplayProperties, typeCompact17MediumProperties } from '@traceair/tds'
import WidgetTags from './WidgetTags'
import { WIDGET_CARD_BORDER_RADIUS, WIDGET_CARD_HEIGHT, WidgetStyles } from './styles'
import { openLinkInNewTab, trackWidgetEvent } from '../Widgets'
import { Breakpoint } from '../../../responsive-breakpoints'
import { Widget } from '../../../types'
import { prefillUrlWithUserInfo } from '../../../libs/user-utils'

const PrimaryWidget = ({ tags, header, description, mediaPath, mediaStyle, link, linkParamsFn }: Widget) => {
  return (
    <div
      className={classes('primary-widget-container', primaryCardContainerStyle, WidgetStyles.hoverTransformStyle)}
      onClick={() => {
        trackWidgetEvent(header)
        openLinkInNewTab(linkParamsFn ? prefillUrlWithUserInfo(link, linkParamsFn) : link)
      }}
    >
      <img src={mediaPath} className={style(mediaStyle)} alt='Widget' />
      <WidgetTags tags={tags} containerPositionStyle={tagsPositionStyle} />
      <div className={classes('primary-widget-info', primaryCardInfoStyle)}>
        <div className={classes('primary-widget-header', headerTextStyle)}>{header}</div>
        <div className={classes('primary-widget-description', descriptionTextStyle)}>{description}</div>
      </div>
    </div>
  )
}

export default PrimaryWidget

const headerTextStyle = style({
  ...typeCompact29DisplayProperties,

  marginBottom: 8
})

const descriptionTextStyle = style({
  ...typeCompact17MediumProperties,

  whiteSpace: 'pre-wrap'
})
const primaryCardInfoStyle = style({
  position: 'absolute',
  left: 16,
  bottom: 20,
  paddingRight: 16,
  color: White,
  textAlign: 'left'
})

const primaryCardContainerStyle = style(
  {
    cursor: 'pointer',
    background: 'linear-gradient(180deg, #353538 0%, #222224 100%)',
    borderRadius: WIDGET_CARD_BORDER_RADIUS,
    width: 400,
    height: WIDGET_CARD_HEIGHT,
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box'
  },
  media({ maxWidth: Breakpoint.TABLET }, { width: 330 })
)

const tagsPositionStyle = style({
  position: 'absolute',
  right: 20,
  top: 16
})
