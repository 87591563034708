import { flatten } from 'lodash'
import { LngLat, LngLatBounds } from 'maplibre-gl'
import { Feature, Point } from 'geojson'

enum Language {
  ru = 'ru',
  en = 'en'
}
type CustomerId = string
type CustomerSystemName = string
type DateString = string // YYYY-MM-DD
type Customer = {
  id: CustomerId
  lang: Language
  name: string
  type: string
}

type SiteId = string

interface ISRS {
  assumedSrs: IAssumedSrs
  globalSrs: IGlobalSrs
  isAssumed: boolean
}

interface IAssumedSrs {
  intermediateSrsName: string
  intermediateSrsEpsg: string
  intermediateSrsProj4: string
  translationMatrixToIntermediateSrs: number[]
}

interface IGlobalSrs {
  name: string
  proj4: string
  epsg: string
}

type Site = {
  id: SiteId
  customer: CustomerSystemName
  name: string
  useMetricSystem: boolean
  isImperial: boolean
  boundary: GeoJSON.Feature<GeoJSON.Polygon>
  targetSurface: unknown
  srs: ISRS
  projectActivity: ProjectActivity
}

type ProjectActivity = 'STARTING' | 'ACTIVE' | 'ARCHIVED'

export function eachCoordinate(
  geojson: GeoJSON.Feature | GeoJSON.FeatureCollection,
  cb: (c: number[], i: number) => void
) {
  if (geojson.type === 'FeatureCollection') {
    geojson.features.forEach(f => eachCoordinate(f, cb))
  } else {
    if (geojson) {
      switch (geojson.geometry.type) {
        case 'Polygon':
          flatten(geojson.geometry.coordinates).forEach(cb)
          break
        case 'LineString':
          geojson.geometry.coordinates.forEach(cb)
          break
        case 'Point':
          cb(geojson.geometry.coordinates, 0)
          break
        case 'MultiPolygon':
          geojson.geometry.coordinates.forEach(coordinates => {
            flatten(coordinates).forEach(cb)
          })
          break
        default:
          throw new Error('Unkonwn geojson feature type')
      }
    }
  }
}

export function geoJSONToLngLats(feature: GeoJSON.Feature) {
  const lls: LngLat[] = []

  eachCoordinate(feature, c => {
    lls.push(new LngLat(c[0], c[1]))
  })

  return lls
}

export function geojsonToLngLatBounds(geojson: GeoJSON.Feature | GeoJSON.FeatureCollection): LngLatBounds {
  const bounds = new LngLatBounds()

  eachCoordinate(geojson, c => {
    bounds.extend(new LngLat(c[0], c[1]))
  })

  return bounds
}

export function getFeatureCollection<T extends GeoJSON.Feature>(features: T[] = []): GeoJSON.FeatureCollection {
  return { type: 'FeatureCollection', features }
}

export const toPointGeoJSON = (lng: number, lat: number, uniqueId: string): Feature<Point> => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lng, lat]
    },
    properties: { uniqueId }
  }
}
