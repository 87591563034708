import * as React from 'react'
import { classes, style } from 'typestyle'
import { Button, Checkbox, Gray20, List, ListItem, ListItemText } from '@traceair/tds'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import useAppStore, { AppStoreType, initialFilters } from '../../AppStore'
import { Filters } from '../../types'
import track from '../../Tracking'
import { saveUserFilters } from '../../helpers'
import { shallow } from 'zustand/shallow'
import { useMemo } from 'react'

const FiltersContainer = () => {
  const filters = useAppStore((state: AppStoreType) => state.filters)
  const customersWithStatistic = useAppStore(state => state.customersWithStatistic)
  const actions = useAppStore((state: AppStoreType) => state.actions)
  const hasSitesWithLotViewer = useAppStore(state => state.hasSitesWithLotViewer)
  const hasSitesWithSchedules = useAppStore(state => state.hasSitesWithSchedules)

  const onFiltersChanged = (changedFilter: Partial<Filters>) => {
    actions.setFilters(changedFilter)
    saveUserFilters()
  }

  const handleClearFiltersClick = () => {
    track('Clear filters button clicked')
    onFiltersChanged(initialFilters)
  }

  const handleFilterCheckboxClick = (changedFilter: Partial<Filters>) => {
    track(`Filter changed`)
    onFiltersChanged(changedFilter)
  }

  const shouldShowClearButton = useMemo(() => !shallow(filters, initialFilters), [filters])

  const pastDueMilestonesCount = useMemo(
    () =>
      customersWithStatistic.reduce(
        (acc, cur) => acc + (cur.withPastDueMilestones !== undefined ? cur.withPastDueMilestones : 0),
        0
      ),
    [customersWithStatistic]
  )

  if (!filters) {
    return null
  }

  return (
    <div className={classes('filters', filtersStyle)}>
      <div className={classes('filters-top', topStyle)}>
        <div className={classes('filters-header', headerStyle)}>Filter</div>
        {shouldShowClearButton && <Button text={'Clear filters'} onClick={handleClearFiltersClick} type='white' />}
      </div>
      <List rounded>
        <ListItem
          size='s'
          className={classes('filter-item-active', filters.active && 'checked')}
          leftCell={<Checkbox onChange={() => null} checked={filters.active} />}
          centerCell={<ListItemText title={t7e(i18nKeys.Active)} />}
          onClick={() => handleFilterCheckboxClick({ active: !filters.active })}
        />
        {hasSitesWithSchedules && (
          <ListItem
            size='s'
            className={classes('filter-item-with-schedules', filters.withSchedule && 'checked')}
            leftCell={<Checkbox onChange={() => null} checked={filters.withSchedule} />}
            centerCell={<ListItemText title={t7e(i18nKeys.WithSchedules)} />}
            onClick={() => handleFilterCheckboxClick({ withSchedule: !filters.withSchedule })}
          />
        )}
        {hasSitesWithLotViewer && (
          <ListItem
            size='s'
            className={classes('filter-item-with-lot-viewer', filters.withLotViewer && 'checked')}
            leftCell={<Checkbox onChange={() => null} checked={filters.withLotViewer} />}
            centerCell={<ListItemText title={t7e(i18nKeys.WithLotViewer)} />}
            onClick={() => handleFilterCheckboxClick({ withLotViewer: !filters.withLotViewer })}
          />
        )}
        {hasSitesWithSchedules && (
          <ListItem
            size='s'
            className={classes('filter-item-with-past-due-milestones', filters.pastDueMilestones && 'checked')}
            leftCell={<Checkbox onChange={() => null} checked={filters.pastDueMilestones} />}
            centerCell={
              <ListItemText
                title={t7e(i18nKeys.PastDueMilestones)}
                description={`${pastDueMilestonesCount} ${t7e(i18nKeys.Sites)}`}
              />
            }
            onClick={() => handleFilterCheckboxClick({ pastDueMilestones: !filters.pastDueMilestones })}
          />
        )}
        <ListItem
          size='s'
          className={classes('filter-item-archived', filters.archived && 'checked')}
          leftCell={<Checkbox onChange={() => null} checked={filters.archived} />}
          centerCell={<ListItemText title={t7e(i18nKeys.Archive)} />}
          onClick={() => handleFilterCheckboxClick({ archived: !filters.archived })}
        />
      </List>
    </div>
  )
}

export default FiltersContainer

const filtersStyle = style({
  borderTop: `1px solid ${Gray20}`,
  padding: '6px 8px 16px'
})

const topStyle = style({
  alignItems: 'center',
  display: 'flex',
  height: 40,
  justifyContent: 'space-between',
  padding: '0 8px'
})

const headerStyle = style({
  fontSize: 20,
  fontWeight: 500,
  lineHeight: 1.2
})
