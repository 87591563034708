import useAppStore from './AppStore'
import { CustomerData, ShortSiteData } from './types'
import { shallow } from 'zustand/shallow'
import { loadSitesData, loadStatistic } from './services/CustomerDashboardApiService'
import { CARD_CHUNK_SIZE } from './layout-constants'
import { debounce } from './libs/debounce'
import axios, { AxiosError } from 'axios'
import * as Sentry from '@sentry/react'

class SitesDataManager {
  private abortController: AbortController | undefined
  private initialLoadPromise: Promise<void> | null = null

  // Store subscriptions
  private unsubscribeDataFiltersChangedHandler: (() => void) | undefined
  private unsubscribeFilterChangedHandler: (() => void) | undefined
  private unsubscribeLoadedSitesSkipCountChangedHandler: (() => void) | undefined
  private unsubscribeCustomersChangedHandler: (() => void) | undefined
  private unsubscribeViewModeChangedHandler: (() => void) | undefined

  subscribeToStore() {
    this.unsubscribeDataFiltersChangedHandler = useAppStore.subscribe(
      ({ filters, sorting, customerIdFilter, siteNameFilterString }) => ({
        filters,
        sorting,
        customerIdFilter,
        siteNameFilterString
      }),
      this.handleSitesDataFiltersChanged,
      { equalityFn: shallow }
    )

    this.unsubscribeFilterChangedHandler = useAppStore.subscribe(state => state.filters, this.handleFiltersChanged)

    this.unsubscribeLoadedSitesSkipCountChangedHandler = useAppStore.subscribe(
      state => state.loadedSitesSkipCount,
      this.handleLoadedSitesSkipCountChanged
    )

    this.unsubscribeCustomersChangedHandler = useAppStore.subscribe(
      state => state.customers,
      this.handleCustomersChanged
    )

    this.unsubscribeViewModeChangedHandler = useAppStore.subscribe(state => state.viewMode, this.handleViewModeChanged)
  }

  unsubscribeFromStore() {
    if (this.unsubscribeDataFiltersChangedHandler) {
      this.unsubscribeDataFiltersChangedHandler()
    }

    if (this.unsubscribeLoadedSitesSkipCountChangedHandler) {
      this.unsubscribeLoadedSitesSkipCountChangedHandler()
    }

    if (this.unsubscribeFilterChangedHandler) {
      this.unsubscribeFilterChangedHandler()
    }

    if (this.unsubscribeCustomersChangedHandler) {
      this.unsubscribeCustomersChangedHandler()
    }

    if (this.unsubscribeViewModeChangedHandler) {
      this.unsubscribeViewModeChangedHandler()
    }
  }

  // Event handlers
  private handleViewModeChanged = () => {
    const { viewMode } = useAppStore.getState()

    if (viewMode === 'MAP') {
      this.loadAllSites()
    }
  }

  private handleLoadedSitesSkipCountChanged = () => {
    const { viewMode } = useAppStore.getState()

    if (viewMode === 'GRID') {
      this.debouncedLoadSitesChunk()
    }
  }

  private handleSitesDataFiltersChanged = () => {
    const { actions, viewMode } = useAppStore.getState()

    actions.clearSitesInfo()

    if (viewMode === 'MAP') {
      // Map mode needs all data immediately when filters change
      this.loadAllSites()
    } else {
      // Grid mode uses chunked loading
      this.debouncedLoadSitesChunk()
    }
  }

  private handleFiltersChanged = () => {
    this.debouncedLoadStatistic()
  }

  private handleCustomersChanged = () => {
    this.loadStatisticForAllSites()
  }

  // Debounced methods to prevent multiple requests on several consequent store updates
  private debouncedLoadSitesChunk = debounce(() => {
    this.loadSitesChunk()
  }, 10)

  private debouncedLoadStatistic = debounce(() => {
    this.loadStatistic()
  }, 10)

  private async loadSingleChunk(count: number, skip: number) {
    const { actions, customers, customerIdFilter, filters, sorting, siteNameFilterString } = useAppStore.getState()

    if (!filters) {
      throw new Error('Filters must be set')
    }

    if (this.abortController) {
      this.abortController.abort()
    }

    this.abortController = new AbortController()

    const selectedCustomer = customers.find((c: CustomerData) => c.id === customerIdFilter)
    const dashboardData = await loadSitesData(
      selectedCustomer ? [selectedCustomer] : customers,
      { count, skip },
      filters,
      sorting,
      siteNameFilterString,
      this.abortController.signal
    )

    actions.setTotalSites(dashboardData.totalSites)

    const mergedSitesData = dashboardData.sites.map(site => {
      const siteCustomer = customers.find((customer: CustomerData) => customer.id === site.customerId)
      const foundSite = siteCustomer?.sites.find((customerSite: ShortSiteData) => customerSite.id === site.siteId)

      if (!foundSite) {
        throw new Error(`Site with ${site.siteId} is not found among sites available for user`)
      }

      return site
    })

    return mergedSitesData.filter(siteData => !blacklistCustomerIds.includes(siteData.customerId))
  }

  private async loadSitesChunk() {
    const { actions, loadedSitesSkipCount } = useAppStore.getState()

    actions.setSitesDataLoading()

    try {
      if (loadedSitesSkipCount === 0) {
        // Track initial load state
        const loadPromise = (async () => {
          const data = await this.loadSingleChunk(CARD_CHUNK_SIZE, 0)

          actions.setSitesDataLoaded(data)
        })()

        this.initialLoadPromise = loadPromise

        await loadPromise
        this.initialLoadPromise = null // Reset after success
      } else {
        const data = await this.loadSingleChunk(CARD_CHUNK_SIZE, loadedSitesSkipCount)

        actions.setSitesDataLoaded(data)
      }
    } catch (err) {
      this.initialLoadPromise = null // Ensure reset on failure

      if ((err as AxiosError).code !== axios.AxiosError.ERR_CANCELED) {
        Sentry.captureException(err, { extra: { message: 'Sites chunk load error' } })
      }
    }
  }

  async loadAllSites() {
    // Reuse initial load if it's in progress
    if (this.initialLoadPromise) {
      await this.initialLoadPromise
    }

    const { sites, totalSites, actions } = useAppStore.getState()

    if (totalSites === null) {
      // Load first chunk to get totalSites
      await this.loadSitesChunk()

      // Now we have totalSites, recursively call to load the rest
      const { totalSites: updatedTotalSites, sites: currentSites } = useAppStore.getState()

      if (updatedTotalSites !== null && currentSites.length < updatedTotalSites) {
        await this.loadAllSites()
      }

      return
    }

    if (sites.length >= totalSites) {
      // We already have all sites
      return
    }

    actions.setSitesDataLoading()

    try {
      // Load all remaining sites in one request
      const skipLength = sites.length
      const remainingSitesCount = totalSites - skipLength
      const remainingSites = await this.loadSingleChunk(remainingSitesCount, skipLength)

      actions.setSitesDataLoaded(remainingSites)
    } catch (err) {
      if ((err as AxiosError).code !== axios.AxiosError.ERR_CANCELED) {
        Sentry.captureException(err, { extra: { message: 'Map mode all sites load error' } })
      }
    }
  }

  prepareStatisticRequest() {
    const { customers } = useAppStore.getState()

    return customers.flatMap((customer: CustomerData) =>
      customer.sites.map(site => ({
        siteId: site.id,
        customerId: customer.id,
        projectActivity: site.projectActivity
      }))
    )
  }

  async loadStatistic() {
    const { actions, filters } = useAppStore.getState()

    if (!filters) {
      return
    }

    actions.setCustomersDataLoading()
    const selectedSites = this.prepareStatisticRequest()
    const statisticData = await loadStatistic(selectedSites, filters)

    actions.setCustomerStatistic(statisticData)
  }

  async loadStatisticForAllSites() {
    const clearedFilters = {
      active: false,
      archived: false,
      pastDueMilestones: false,
      withLotViewer: false,
      withSchedule: false
    }
    const { actions } = useAppStore.getState()
    const selectedSites = this.prepareStatisticRequest()
    const statisticData = await loadStatistic(selectedSites, clearedFilters)
    const withLv = statisticData.some(c => !!c.withLotViewer)
    const withSchedules = statisticData.some(c => !!c.withSchedules)

    actions.setHasSitesWithLotViewer(withLv)
    actions.setHasSitesWithSchedules(withSchedules)
  }
}

const sitesDataManager = new SitesDataManager()

export default sitesDataManager
