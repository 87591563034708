import enableLocationService from './LocationService'
import useMapStore from '../../stores/MapStore'
import { useShallow } from 'zustand/react/shallow'
import { memo, useCallback, useEffect } from 'react'
import { Blue80, ButtonIcon, ButtonType, White, BlackOpacity60 } from '@traceair/tds'
import { LocationIcon, LocationNotAvailableIcon } from '@traceair/webapp-icons'
import { classes, stylesheet } from 'typestyle'
import { MapRef } from 'react-map-gl/maplibre'
import { locationManager } from '../../layers/location_layer/LocationLayerManager'

enableLocationService()

type LocationControlProps = {
  map?: MapRef
}

export const LocationControl = memo(function LocationControl({ map }: LocationControlProps) {
  const { locationError, locationIsEnabled, locationNeedToFly } = useMapStore(
    useShallow(state => ({
      locationIsEnabled: state.locationIsEnabled,
      locationNeedToFly: state.locationNeedToFly,
      locationError: state.locationError
    }))
  )

  useEffect(() => {
    if (!map) {
      return
    }

    locationManager.setMap(map)
    locationManager.subscribeToStore()

    return () => {
      locationManager.unsubscribeFromStore()
    }
  }, [map])

  const handleClick = useCallback(() => {
    const { locationIsEnabled, locationNeedToFly } = useMapStore.getState()

    if (!locationIsEnabled) {
      locationManager.handleLocationIsEnabledChange()

      if (locationNeedToFly) {
        locationManager.flyToLocationMarker()
      }

      return
    }

    if (locationNeedToFly) {
      locationManager.flyToLocationMarker()

      return
    }

    locationManager.handleHideLocation()
  }, [])

  function getIcon() {
    if (locationError) {
      return <LocationNotAvailableIcon color={White} />
    }

    if (locationNeedToFly && locationIsEnabled) {
      return <LocationIcon color={Blue80} />
    }

    if (locationIsEnabled) {
      return <LocationIcon color={White} />
    }

    return <LocationIcon color={White} />
  }

  const getButtonType = (): ButtonType => {
    const { locationNeedToFly, locationIsEnabled } = useMapStore.getState()
    const isInitialState = !locationNeedToFly && locationIsEnabled

    if (isInitialState) {
      return 'default'
    }

    return 'black'
  }

  const buttonType = getButtonType()

  return (
    <ButtonIcon
      type={buttonType}
      className={classes(css.locationButton, buttonType === 'black' && css.locationBlackButton)}
      onClick={handleClick}
      icon={getIcon()}
    />
  )
})

const css = stylesheet({
  locationButton: {
    marginTop: 4
  },
  locationBlackButton: {
    background: `${BlackOpacity60} !important`, // Needed to override button color as `ButtonIcon` doesn't support it
    $nest: {
      '&:hover': {
        background: `${BlackOpacity60} !important`
      }
    }
  }
})
