import type { MapProps } from 'react-map-gl/maplibre'
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_WIDTH } from '../../layout-constants'

export const CONTROLS_MARGIN_TOP = 24
export const CONTROLS_MARGIN_RIGHT = 16
export const CONTROLS_MARGIN_BOTTOM = 0
const CONTROLS_MARGIN_LEFT = 24

const MAP_PADDING_TOP = CONTROLS_MARGIN_TOP + 40 // 40px for top controls height
const MAP_PADDING_RIGHT = CONTROLS_MARGIN_RIGHT + 40 // 40px for right controls width
const MAP_PADDING_BOTTOM = CONTROLS_MARGIN_BOTTOM + 32 // for bottom controls height

export function GET_MAP_PADDING_LEFT(isSidebarOpen: boolean) {
  return isSidebarOpen ? CONTROLS_MARGIN_LEFT + SIDEBAR_WIDTH : SIDEBAR_COLLAPSED_WIDTH
}

export function getMapPadding(isSidebarOpen: boolean) {
  return {
    top: MAP_PADDING_TOP,
    right: MAP_PADDING_RIGHT,
    bottom: MAP_PADDING_BOTTOM,
    left: GET_MAP_PADDING_LEFT(isSidebarOpen)
  }
}

export const INITIAL_VIEW_STATE: MapProps['initialViewState'] = {
  latitude: 40.67,
  longitude: -103.59,
  zoom: 3,
  padding: getMapPadding(true) // assuming sidebar is more often open by default
}

export const MAX_MAP_ZOOM = 25

export const Z_INDICES = {
  MAP: 0,
  FOOTER_HEADER: 1,

  SIDEBAR: 2,
  BASE_LAYER_SWITCHER: 2,
  ATTRIBUTION_CONTROL: 2,

  // Markers on the map have their z-indeces calculated from their latitude coordinates and range from 1000 to 90000
  SELECTED_MAP_MARKER: 90020,

  CLUSTER_MARKER: 900,

  HOVERED_MARKER: 90010,

  EXPANDED_CARD: 100000
}
