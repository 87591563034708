import useAppStore from '../../../AppStore'
import useMapStore, { Point } from '../stores/MapStore'
import { useShallow } from 'zustand/react/shallow'
import { trackMapMode } from '../../../Tracking'
import PointMarker from './point_marker/PointMarker'

interface MapPointsProps {
  points: Point[]
}

export function MapPoints({ points }: MapPointsProps) {
  const siteViewToken = useAppStore(state => state.siteViewToken)
  const {
    sitesDataByUniqueId,
    selectedSiteUniqueId,
    actions: { setSelectedSiteUniqueId }
  } = useMapStore(
    useShallow(state => ({
      sitesDataByUniqueId: state.sitesDataByUniqueId,
      selectedSiteUniqueId: state.selectedSiteUniqueId,
      actions: state.actions
    }))
  )

  const handleClick = (uniqueId: string) => {
    trackMapMode('click on site marker')

    if (selectedSiteUniqueId === uniqueId) {
      setSelectedSiteUniqueId(null)

      return
    }

    setSelectedSiteUniqueId(uniqueId)
  }

  return (
    <>
      {points.map((point: Point) => {
        const { coordinates, properties } = point
        const uniqueId = properties.uniqueId
        const siteData = sitesDataByUniqueId[uniqueId]

        if (!siteData) {
          return null
        }

        const isSelectedSite = selectedSiteUniqueId === uniqueId

        return (
          <PointMarker
            key={uniqueId}
            siteViewToken={siteViewToken}
            siteData={siteData}
            isSelectedSite={isSelectedSite}
            coordinates={coordinates}
            onClick={() => handleClick(uniqueId)}
          />
        )
      })}
    </>
  )
}
