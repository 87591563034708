import useMapStore from '../../stores/MapStore'
import { i18nKeys } from '../../../../i18n/i18nkeys'
import { t7e } from '../../../../i18n/i18n'

class LocationService {
  private watchPositionHandlerId?: number

  constructor(
    private onGeoSuccess: (position: GeolocationPosition) => void,
    private onGeoError: (err: GeolocationPositionError) => void
  ) {}

  enableLocationWatch() {
    this.watchPositionHandlerId = navigator.geolocation.watchPosition(this.onGeoSuccess, this.onGeoError, {
      enableHighAccuracy: true
    })
  }

  disableLocationWatch() {
    if (this.watchPositionHandlerId) {
      navigator.geolocation.clearWatch(this.watchPositionHandlerId)
    }
  }
}

export default function enableLocationService() {
  const service = new LocationService(geoSuccessCallback, geoErrorCallback)

  function geoSuccessCallback(position: GeolocationPosition) {
    const { locationCoordinates, locationError } = useMapStore.getState()

    if (locationError || locationCoordinates) {
      return
    }

    useMapStore.getState().actions.setLocationCoordinates(position.coords)
  }

  function geoErrorCallback(err: GeolocationPositionError) {
    if (err.code === err.PERMISSION_DENIED) {
      alert(t7e(i18nKeys.PermissionsError))
    }

    if (err.code === err.POSITION_UNAVAILABLE || err.code === err.TIMEOUT) {
      alert(t7e(i18nKeys.LocationError))
    }

    useMapStore.getState().actions.setLocationError(true)
  }

  useMapStore.subscribe(
    state => state.locationIsEnabled,
    locationIsEnabled => {
      if (locationIsEnabled) {
        service.enableLocationWatch()
      } else {
        service.disableLocationWatch()
      }
    }
  )

  return service
}
