import { classes, media, style } from 'typestyle'
import { typeCompact24Display, TextItem, Button } from '@traceair/tds'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import SortingSelector from './SortingSelector'
import { CustomerData } from '../../types'
import useAppStore, { AppStoreType } from '../../AppStore'
import UpsellRequestControl from './UpsellRequestControl'
import { MenuIcon } from '@traceair/webapp-icons'
import { FunctionComponent } from 'react'
import track from '../../Tracking'
import { Breakpoint, showOnlyOnTabletsOrMobiles } from '../../responsive-breakpoints'
import SitesViewSwitcher from './SitesViewSwitcher'

interface ISiteListTopContainerProps {
  onSidebarVisibilityChanged: (value: boolean) => void
}

const SiteListTopContainer: FunctionComponent<ISiteListTopContainerProps> = ({ onSidebarVisibilityChanged }) => {
  const customers = useAppStore((state: AppStoreType) => state.customers)
  const customerIdFilter = useAppStore((state: AppStoreType) => state.customerIdFilter)

  const selectedCustomer = customers.find((c: CustomerData) => c.id === customerIdFilter)

  return (
    <div className={classes('dashboard-sitecards-container-top', sitecardsTopStyle)}>
      <TextItem
        text={selectedCustomer === undefined ? t7e(i18nKeys.AllProjects) : selectedCustomer.name}
        style={classes('dashboard-header', typeCompact24Display, headerStyle)}
      />
      <Button
        icon={MenuIcon}
        text={t7e(i18nKeys.SearchProject)}
        onClick={() => {
          track('Sidebar expand button clicked')
          onSidebarVisibilityChanged(true)
        }}
        className={showOnlyOnTabletsOrMobiles}
        style='secondary'
      />
      <div className={classes('sitelist-toolbar-container', toolbarStyle)}>
        <SortingSelector />
        <UpsellRequestControl />
        <SitesViewSwitcher />
      </div>
    </div>
  )
}

export default SiteListTopContainer

const sitecardsTopStyle = style({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginBottom: 24,
  position: 'relative'
})

const headerStyle = style(
  {
    fontSize: 32,
    fontWeight: 500,
    lineHeight: 1.2
  },
  media(
    { maxWidth: Breakpoint.TABLET },
    {
      marginBottom: 24,
      width: '100%'
    }
  ),
  media(
    { maxWidth: Breakpoint.SMALL_TABLET },
    {
      paddingRight: 84
    }
  )
)

const toolbarStyle = style(
  {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  media(
    { maxWidth: Breakpoint.SMALL_TABLET },
    {
      width: '100%',
      alignItems: 'flex-start',
      marginLeft: 0,
      marginTop: 8
    }
  )
)
