import * as React from 'react'
import en from './en'
import { enUS as enDFNSLocale } from 'date-fns/locale'
import { i18nKeys } from './i18nkeys'

const dictionary = en
const locale = enDFNSLocale

export function getDFNSLocale() {
  return locale
}

export function t7e(key: i18nKeys, ...args: string[]): string {
  const v = dictionary[key]

  if (typeof v === 'function') {
    return v(...args)
  }

  return v
}

// to handle &nbsp; and so on
export function html(str: string): React.ReactNode {
  return React.createElement('span', {
    dangerouslySetInnerHTML: {
      __html: str
    }
  })
}
