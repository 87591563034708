import { INavigationBarButtonProps } from '@traceair/tds'
import { BackofficeAppLogoIcon } from '@traceair/webapp-icons'
import { IAcl } from '../../../../jwt-utils'
import { checkIfCanWorkInBackoffice } from '../../../../utils'
import track from '../../../../Tracking'
import { t7e } from '../../../../i18n/i18n'
import { i18nKeys } from '../../../../i18n/i18nkeys'

export function createBackofficeAppButton(acl: IAcl): INavigationBarButtonProps | null {
  const canWorkInBackoffice = checkIfCanWorkInBackoffice(acl)

  if (!canWorkInBackoffice || !BackofficeUrl) {
    return null
  }

  return {
    icon: BackofficeAppLogoIcon,
    text: t7e(i18nKeys.Backoffice),
    onClick: () => {
      track('Backoffice Button Click')
      window.open(BackofficeUrl, '_blank')
    }
  }
}
