import { classes, stylesheet } from 'typestyle'
import PointIcon from '../../../../icons/PointIcon'
import { BlackOpacity50, Blue80, Gray110, typeCompact12MediumProperties, White, WhiteOpacity80 } from '@traceair/tds'
import { SiteMapData } from '../../stores/MapStore'
import { typeCompact10MediumProperties } from '@traceair/tds/src/typography/Typography'
import { JWTToken } from '../../../../jwt-utils'
import SiteCardPreview from './SiteCardPreview'
import { memo, useEffect, useState } from 'react'
import { Marker } from 'react-map-gl/maplibre'
import { Position } from 'geojson'
import { Z_INDICES } from '../../constants'

type PointMarkerProps = {
  siteData: SiteMapData
  siteViewToken: JWTToken | undefined
  onClick: () => void
  isSelectedSite: boolean
  coordinates: Position
}

const PointMarker = memo(
  function PointMarker({ siteData, isSelectedSite, onClick, siteViewToken, coordinates }: PointMarkerProps) {
    const { name, customerName } = siteData
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
      // Start animation on next frame to make sure the element is rendered
      // (fixes the issues when sometimes the animation is not played)
      requestAnimationFrame(() => setIsVisible(true))

      return () => {
        setIsVisible(false)
      }
    }, [])

    const pointColorIcon = isSelectedSite ? Blue80 : BlackOpacity50

    const siteDataContainerClassName = classes(css.siteDataContainer, isSelectedSite && selectedCss.siteDataContainer)
    const siteNameClassName = classes(css.siteName, isSelectedSite && selectedCss.siteName)
    const customerNameClassName = classes(css.customerName, isSelectedSite && selectedCss.customerName)

    return (
      <Marker
        longitude={coordinates[0]}
        latitude={coordinates[1]}
        style={{
          /**
           * Latitude is used to calculate zIndex: more southern sites are on top, algorithm:
           * 1. Take latitude (from -90° to 90°)
           * 2. Subtract from 90° to invert (so southern points get higher values, range 0-180)
           * 3. Divide by total range (180) to normalize to 0-1
           * 4. Multiply by 89000 to get desired range
           * 5. Add 1000 as base z-index
           * 6. Use Math.floor to get integer
           *
           * Resulting z-indices range from 1000 (90°N) to 90000 (90°S)
           * Selected points get z-index 90001 to always stay on top
           */
          zIndex: isSelectedSite
            ? Z_INDICES.SELECTED_MAP_MARKER
            : Math.floor(1000 + (89000 * (90 - coordinates[1])) / 180)
        }}
        className={css.marker}
      >
        <div className={isVisible ? css.pointMarkerVisible : css.pointMarkerHidden}>
          <PointIcon color={pointColorIcon} />
          <div className={siteDataContainerClassName} onClick={onClick}>
            <SiteCardPreview siteData={siteData} siteViewToken={siteViewToken} />
            <div className={css.siteNames}>
              <div className={siteNameClassName}>{name}</div>

              <div className={customerNameClassName}>{customerName}</div>
            </div>
          </div>
        </div>
      </Marker>
    )
  },
  // Only re-render if selected site or site data changes
  (prevProps, nextProps) => {
    return (
      prevProps.isSelectedSite === nextProps.isSelectedSite &&
      prevProps.siteData.uniqueId === nextProps.siteData.uniqueId &&
      prevProps.siteViewToken === nextProps.siteViewToken
    )
  }
)

const css = stylesheet({
  marker: {
    $nest: {
      '&:hover': {
        zIndex: `${Z_INDICES.HOVERED_MARKER} !important`
      }
    }
  },

  pointMarkerVisible: {
    opacity: 1,
    transform: 'scale(1)',
    transition: 'opacity 200ms ease, transform 200ms ease'
  },
  pointMarkerHidden: {
    opacity: 0,
    transform: 'scale(0)'
  },
  siteDataContainer: {
    position: 'absolute',
    top: -9,
    transition: 'transform 150ms ease-in-out',
    transform: 'translate(calc(-50% + 8px), -100%)', // 8px is half of the icon width

    padding: '4px',

    minWidth: 100,
    maxWidth: 200,

    textAlign: 'center',

    boxShadow:
      '0px 1px 3px 0px rgba(16, 24, 41, 0.1), 0px 1px 2px 0px rgba(16, 24, 41, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.2)',

    backgroundColor: White,
    borderRadius: 8,

    $nest: {
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -6,
        left: '50%',
        transform: 'translateX(-50%)',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderTop: `6px solid ${White}`
      },
      '&:hover': {
        transform: 'translate(calc(-50% + 8px), calc(-100% - 6px))'
      }
    }
  },
  siteName: {
    ...typeCompact12MediumProperties
  },
  siteNames: {
    textAlign: 'start',
    padding: 4
  },
  customerName: {
    ...typeCompact10MediumProperties,
    color: Gray110,
    paddingTop: 2
  }
})

const selectedCss = stylesheet({
  siteDataContainer: {
    $nest: {
      '&&': {
        backgroundColor: Blue80,
        $nest: {
          '&::after': {
            borderTop: `6px solid ${Blue80}`
          }
        }
      }
    }
  },
  siteName: {
    $nest: {
      '&&': {
        color: White
      }
    }
  },
  customerName: {
    $nest: {
      '&&': {
        color: WhiteOpacity80
      }
    }
  }
})

export default PointMarker
