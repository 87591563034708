import type { MapRef } from 'react-map-gl/maplibre'
import useMapStore from './stores/MapStore'
import { useShallow } from 'zustand/react/shallow'
import { useEffect } from 'react'
import useAppStore from '../../AppStore'
import { getMapPadding } from './constants'
import { LngLatBounds } from 'maplibre-gl'
import { isEqual } from 'lodash'

// React on map zoom control change
export function useHandleMapZoomControlChange(map: MapRef | undefined) {
  const zoomDelta = useMapStore(useShallow(state => state.zoomDelta))

  useEffect(() => {
    if (zoomDelta === null || !map) {
      return
    }

    map.flyTo({ zoom: map.getZoom() + zoomDelta })

    useMapStore.getState().actions.clearZoomDelta()
  }, [zoomDelta, map])
}

// React on sidebar visibility change
export function useHandleSidebarVisibilityChange(map: MapRef | undefined) {
  const isSidebarOpen = useAppStore(useShallow(state => state.isSidebarOpen))

  useEffect(() => {
    if (map) {
      const newPadding = getMapPadding(isSidebarOpen)
      const currentPadding = map.getPadding()

      if (isEqual(newPadding, currentPadding)) {
        return
      }

      map.setPadding(newPadding)
    }
  }, [isSidebarOpen, map])
}

// Fit the map bounds to the sites that are currently displayed
export function useMapFit(map: MapRef | undefined) {
  const { sitesDataByUniqueId, needsFitToSites } = useMapStore(
    useShallow(state => ({
      sitesDataByUniqueId: state.sitesDataByUniqueId,
      needsFitToSites: state.needsFitToSites
    }))
  )

  useEffect(() => {
    if (!needsFitToSites || !map) {
      return
    }

    const coordinates = Object.values(sitesDataByUniqueId)
      .map(site => site.coordinates)
      .filter((coord): coord is [number, number] => Array.isArray(coord) && coord.length === 2)

    if (coordinates.length === 0) {
      return
    }

    const bounds = coordinates.reduce(
      (bounds, coord) => bounds.extend(coord),
      new LngLatBounds(coordinates[0], coordinates[0])
    )

    map.fitBounds(bounds, {
      padding: 100,
      maxZoom: 14,
      duration: 2000,
      curve: 1.42
    })

    useMapStore.getState().actions.setNeedsFitToSites(false)
  }, [map, sitesDataByUniqueId, needsFitToSites])
}
