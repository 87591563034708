import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { sessionIDInterceptor, sessionIdInterceptor } from './SessionIDInterceptor'
import { JWTToken } from '../jwt-utils'

let loginService: LoginService | undefined

export function initLoginService(loginServiceUrl: string): void {
  loginService = new LoginService(loginServiceUrl)
}

export function getLoginService() {
  if (!loginService) {
    throw new Error('Login Service is not instantiated. Call `initLoginService` function first')
  }

  return loginService
}

interface IMe {
  accessToken: string
}

export class LoginService {
  loginService: AxiosInstance

  constructor(loginServiceBaseUrl: string) {
    this.loginService = axios.create({
      baseURL: loginServiceBaseUrl
    })
    this.loginService.interceptors.request.use(config => sessionIdInterceptor(config))
  }

  me(customer?: string, permissions?: string[], site?: string): Promise<string> {
    const data: { customer?: string; permissions?: string[]; site?: string } = customer ? { customer } : {}

    if (permissions) {
      data.permissions = permissions
    }

    if (site) {
      data.site = site
    }

    return axios
      .post('api/me', data, {
        baseURL: this.loginService.defaults.baseURL,
        withCredentials: true,
        headers: {
          'TraceAir-Session-Id': sessionIDInterceptor
        }
      })
      .then((response: AxiosResponse<IMe>) => response.data.accessToken)
  }

  logout(): Promise<void> {
    return this.loginService.post('api/logout', {}, { withCredentials: true })
  }

  changePassword(token: JWTToken, email: string, currentPass: string, newPass: string, newPassConfirmed: string) {
    return this.loginService.post(
      `api/users/${email}/change-password`,
      { currentPass, newPass, newPassConfirmed },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  }

  async getTermsOfUse(): Promise<{ url: string }> {
    const res = await this.loginService.get('api/terms-of-use/latest')

    return res.data.termsOfUse
  }
}
