import * as React from 'react'
import { classes, style } from 'typestyle'
import { Black, Menu, MenuItem } from '@traceair/tds'
import { AddScheduleIcon, DroneRequestIcon, FolderAddIcon, PadIcon, PanoramaIcon } from '@traceair/webapp-icons'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import track from '../../Tracking'
import useAppStore, { AppStoreType } from '../../AppStore'
import { useOnClickOutside } from '../../hooks'
import { UpsellPopupType, showUpsellPopup } from '../upsell_popup'

interface IUpsellRequestMenuProps {
  onClose: () => void
}

function UpsellRequestMenu({ onClose }: IUpsellRequestMenuProps) {
  const user = useAppStore((state: AppStoreType) => state.user)
  const menuRef = React.useRef<HTMLDivElement>(null)

  useOnClickOutside(menuRef, onClose)

  if (!user) {
    return
  }

  function requestNewPano() {
    track('Request Pano Button clicked')

    showUpsellPopup(UpsellPopupType.AddPanoramas)

    onClose()
  }

  function requestNewProject() {
    track('Add Project Button clicked')

    const href = `${ProjectsManagementAppUrl}/new-project-request?retpath=${window.location.href}`

    window.open(href, '_blank')
  }

  function requestNewScan() {
    track('Request Scan clicked')

    showUpsellPopup(UpsellPopupType.RequestFlights)

    onClose()
  }

  function requestSchedule() {
    track('Request Schedule clicked')

    showUpsellPopup(UpsellPopupType.AddSchedules)

    onClose()
  }

  function requestLotViewer() {
    track('Request Lot Viewer clicked')

    showUpsellPopup(UpsellPopupType.AddLotViewer)

    onClose()
  }

  return (
    <div ref={menuRef}>
      <Menu className={classes('create-request-menu', menuStyle)}>
        <MenuItem
          className='request-new-project'
          icon={<FolderAddIcon color={Black} />}
          title={t7e(i18nKeys.AddProject)}
          onClick={requestNewProject}
        />
        <MenuItem
          className='request-lot-viewer'
          icon={<PadIcon color={Black} />}
          title={t7e(i18nKeys.AddLotViewer)}
          onClick={requestLotViewer}
        />
        <MenuItem
          className='request-schedule'
          icon={<AddScheduleIcon color={Black} />}
          title={t7e(i18nKeys.AddSchedules)}
          onClick={requestSchedule}
        />
        <MenuItem
          className='request-new-panorama'
          icon={<PanoramaIcon color={Black} />}
          title={t7e(i18nKeys.RequestPano)}
          onClick={requestNewPano}
        />
        <MenuItem
          className='request-new-flights'
          icon={<DroneRequestIcon color={Black} />}
          title={t7e(i18nKeys.RequestNewScan)}
          onClick={requestNewScan}
        />
      </Menu>
    </div>
  )
}

export default UpsellRequestMenu

const menuStyle = style({
  position: 'absolute',
  right: 0,
  top: 42,
  width: 220
})
