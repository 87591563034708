import 'maplibre-gl/dist/maplibre-gl.css'

import Map, { AttributionControl } from 'react-map-gl/maplibre'
import { CONTROLS_MARGIN_BOTTOM, CONTROLS_MARGIN_TOP, INITIAL_VIEW_STATE, MAX_MAP_ZOOM, Z_INDICES } from './constants'
import { classes, style } from 'typestyle'
import UpsellRequestControl from '../sites_grid/UpsellRequestControl'
import SitesViewSwitcher from '../sites_grid/SitesViewSwitcher'
import DashboardLogoIcon from '../../icons/DashboardLogoIcon'
import { MapAlerts } from './components/MapAlerts'
import { SitesMapContent } from './SitesMapContent'

function SitesMap() {
  return (
    <>
      <div className={classes('map-header', headerControlsStyle)}>
        <UpsellRequestControl />
        <SitesViewSwitcher />
      </div>

      <Map
        initialViewState={INITIAL_VIEW_STATE}
        style={mapStyle}
        reuseMaps
        attributionControl={false}
        maxZoom={MAX_MAP_ZOOM}
        dragRotate={false}
      >
        <SitesMapContent />
        <div className={classes('map-footer', footerControlsStyle)}>
          <MapAlerts />
          <div>
            <DashboardLogoIcon />
          </div>
        </div>
        <AttributionControl compact style={{ zIndex: Z_INDICES.ATTRIBUTION_CONTROL }} />
      </Map>
    </>
  )
}

const mapStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '32px 32px 0 0',

  zIndex: Z_INDICES.MAP
}

const footerControlsStyle = style({
  position: 'absolute',
  width: '100%',
  bottom: CONTROLS_MARGIN_BOTTOM,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 12,

  zIndex: Z_INDICES.FOOTER_HEADER
})

const headerControlsStyle = style({
  position: 'absolute',
  top: CONTROLS_MARGIN_TOP,
  right: CONTROLS_MARGIN_TOP + 8,
  display: 'flex',
  gap: 8,
  alignItems: 'center',

  zIndex: Z_INDICES.FOOTER_HEADER
})

export default SitesMap
