import { classes, stylesheet } from 'typestyle'
import { Blue80, White, typeCompact12MediumProperties } from '@traceair/tds'
import { WidgetTagType } from '../../../types'

interface WidgetCardTagProps {
  text: string
  isPrimary?: boolean
}

interface WidgetCardTagsProps {
  tags: WidgetTagType[]
  containerPositionStyle?: string
}

const WidgetTag = ({ text, isPrimary }: WidgetCardTagProps) => {
  return (
    <div className={classes('widget-card-tag', TagStyles.tag, isPrimary && PrimaryTagStyles.tag)}>
      <div className={TagStyles.font}>{text}</div>
    </div>
  )
}

const WidgetTags = ({ tags, containerPositionStyle }: WidgetCardTagsProps) => {
  return (
    <div className={classes('widget-card-tags-container', TagStyles.tagContainer, containerPositionStyle)}>
      {tags.map(tag => {
        if (typeof tag === 'string') {
          return <WidgetTag text={tag} key={tag} />
        } else {
          return <WidgetTag text={tag.text} isPrimary={tag.isPrimary} key={tag.text} />
        }
      })}
    </div>
  )
}

export default WidgetTags

const TagStyles = stylesheet({
  font: {
    ...typeCompact12MediumProperties,
    padding: '8px 12px',
    maxHeight: '100%'
  },

  tagContainer: {
    display: 'flex',
    gap: 8,
    zIndex: 2,
    color: White
  },

  tag: {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: 16,
    width: 'fit-content',
    maxHeight: 30
  }
})

const PrimaryTagStyles = stylesheet({
  tag: {
    $nest: {
      [`&.${TagStyles.tag}`]: {
        backgroundColor: Blue80
      }
    }
  }
})
