import { useCallback, useEffect, useMemo } from 'react'
import type { MapRef } from 'react-map-gl/maplibre'
import { Layer, Source } from 'react-map-gl/maplibre'
import { MarkersLayerManager } from './MarkersLayerManager'
import { throttle } from 'lodash'
import ClusterMarker from '../../components/ClusterMarker'
import useMapStore, { Cluster, Point } from '../../stores/MapStore'
import markersLayerDataManager from './MarkersLayerDataManager'
import SelectedSiteCardOnMap from '../../components/site_card_map/SelectedSiteCardOnMap'
import { useShallow } from 'zustand/react/shallow'
import { trackMapMode } from '../../../../Tracking'
import { MapPoints } from '../../components/MapPoints'

interface MarkersLayerProps {
  map: MapRef
}

export type Markers = {
  clusters: Cluster[]
  points: Point[]
}

function MarkersLayer({ map }: MarkersLayerProps) {
  const {
    sitesDataByUniqueId,
    points,
    clusters,
    selectedSiteUniqueId,
    actions: { clearSelectedSiteUniqueId }
  } = useMapStore(
    useShallow(state => ({
      sitesDataByUniqueId: state.sitesDataByUniqueId,
      points: state.points,
      clusters: state.clusters,
      selectedSiteUniqueId: state.selectedSiteUniqueId,
      actions: state.actions
    }))
  )

  const selectedSite = selectedSiteUniqueId ? sitesDataByUniqueId[selectedSiteUniqueId] : null

  const isSourceReady = Boolean(map.getSource(MarkersLayerManager.sourceId))
  const markerLayerManager = useMemo(() => new MarkersLayerManager(map), [map, isSourceReady])

  useEffect(() => {
    markersLayerDataManager.subscribeToStore()

    return () => {
      markersLayerDataManager.unsubscribeFromStore()
    }
  }, [])

  const updateClusters = useCallback(
    throttle(async () => {
      const previousMarkers = markersLayerDataManager.getMarkers()
      const updatedMarkers = await markerLayerManager.updateMarkers(previousMarkers)

      if (updatedMarkers) {
        markersLayerDataManager.setMarkers(updatedMarkers)
      }
    }, 200),
    [markerLayerManager]
  )

  useEffect(() => {
    map.on('zoomend', updateClusters)
    map.on('render', updateClusters)

    return () => {
      map.off('zoomend', updateClusters)
      map.off('render', updateClusters)
    }
  }, [map, isSourceReady, updateClusters])

  if (!markerLayerManager) {
    return null
  }

  return (
    <Source
      data={markersLayerDataManager.getFeatureCollectionSites()}
      type='geojson'
      {...markerLayerManager.getSource()}
    >
      <Layer {...markerLayerManager.getLayer()} />
      {clusters?.map(({ coordinates, pointCount, id, center, expansionZoom, parentCoordinates }) => (
        <ClusterMarker
          key={id}
          fromCoordinates={parentCoordinates}
          toCoordinates={coordinates}
          count={pointCount}
          onClick={() => {
            trackMapMode('click on cluster marker')
            markerLayerManager.handleClick(center, expansionZoom, id)
          }}
        />
      ))}
      {points ? <MapPoints points={points} /> : null}
      {selectedSite ? <SelectedSiteCardOnMap site={{ ...selectedSite }} onClose={clearSelectedSiteUniqueId} /> : null}
    </Source>
  )
}

export default MarkersLayer
