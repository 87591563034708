import useMapStore from '../stores/MapStore'
import { classes, stylesheet } from 'typestyle'
import { BlackOpacity60, Gray100, White } from '@traceair/tds'
import { trackMapMode } from '../../../Tracking'

function ZoomControl() {
  const setZoomDelta = useMapStore(state => state.actions.setZoomDelta)

  const handleZoomInClick = () => {
    trackMapMode('click to zoom in control')
    setZoomDelta(1)
  }

  const handleZoomOutClick = () => {
    trackMapMode('click to zoom out control')
    setZoomDelta(-1)
  }

  return (
    <div className={classes('zoom-control', css.zoomControl)}>
      <button className={classes('zoom-control-plus', css.button)} onClick={handleZoomInClick}>
        +
      </button>
      <div className={classes('zoom-control-separator', css.separator)} />
      <button className={classes('zoom-control-minus', css.button)} onClick={handleZoomOutClick}>
        –
      </button>
    </div>
  )
}

const css = stylesheet({
  zoomControl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 80,
    background: BlackOpacity60,
    borderRadius: 12
  },
  button: {
    fontSize: 20,
    color: White,
    width: '100%',
    height: '100%'
  },
  separator: {
    height: 2,
    width: 24,
    background: Gray100
  }
})

export default ZoomControl
