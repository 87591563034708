import { RefObject, TouchEventHandler, useCallback, useEffect, useState } from 'react'
import useAppStore from './AppStore'
import { addIntercom } from './helpers'

export function useOnClickOutside(ref: RefObject<HTMLDivElement>, handler: (event: MouseEvent | TouchEvent) => void) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export const useIntercom = () => {
  const { enabled, token } = INTERCOM /* Object from config */
  const { user } = useAppStore.getState()

  useEffect(() => {
    if (enabled && token && user) {
      addIntercom(token)

      window.Intercom('boot', {
        app_id: token,
        hide_default_launcher: true,
        email: user.email,
        name: `${user.name} ${user.lastname}`,
        title: user.title,
        lang: user.lang
      })
    }
  }, [user])
}

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowWidth
}

export const useSwipe = (onSwipeLeft: (() => void) | null, onSwipeRight: (() => void) | null) => {
  const [startX, setStartX] = useState(0)

  const handleTouchStart: TouchEventHandler = useCallback(e => {
    setStartX(e.touches[0].clientX)
  }, [])

  const handleTouchEnd: TouchEventHandler = useCallback(
    e => {
      const endX = e.changedTouches[0].clientX
      const deltaX = endX - startX

      if (deltaX < 0 && onSwipeLeft !== null) {
        onSwipeLeft()
      }

      if (deltaX > 0 && onSwipeRight !== null) {
        onSwipeRight()
      }
    },
    [startX]
  )

  return [handleTouchStart, handleTouchEnd]
}
