import { classes, style } from 'typestyle'
import { Blue80, Gray20, White } from '@traceair/tds'
import useAppStore from '../../../AppStore'
import ContextMenuButton from './context_menu/ContextMenuButton'
import { CustomerData, SiteData } from '../../../types'
import { CARD_HEIGHT } from '../../../layout-constants'
import { FunctionComponent } from 'react'
import SiteCardContent from './SiteCardContent'

interface ISiteCardProps {
  site: SiteData
}

const SiteCard: FunctionComponent<ISiteCardProps> = ({ site }) => {
  const customers = useAppStore(state => state.customers)

  const customer = customers.find((c: CustomerData) => c.id === site.customerId)

  if (!customer) {
    return null
  }

  return (
    <div className={classes('dashboard-site-card', commonCardStyle, cardStyle)}>
      <ContextMenuButton customer={customer} site={site} />
      <SiteCardContent site={site} customer={customer} />
    </div>
  )
}

export const commonCardStyle = style({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  background: White,
  borderRadius: 16,
  overflow: 'hidden',
  maxWidth: 480,
  paddingBottom: 16,
  justifyContent: 'flex-end',
  border: `1px solid ${Gray20}`,
  boxShadow:
    '0px 1.751734972000122px 3.130388021469116px 0px rgba(0, 0, 0, 0.04), 0px 9px 25px 0px rgba(0, 0, 0, 0.07)',

  $nest: {
    '&:hover': {
      border: `1px solid ${Blue80}`,
      boxShadow:
        '0px 1.751734972000122px 3.130388021469116px 0px rgba(0, 122, 255, 0.1), 0px 9px 25px 0px rgba(0, 122, 255, 0.1)'
    },
    '&:hover .site-card-context-menu-button': {
      display: 'flex'
    }
  }
})

export const cardStyle = style({
  height: CARD_HEIGHT
})

export default SiteCard
