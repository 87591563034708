import { Marker } from 'react-map-gl/maplibre'
import LocationIndicator from '../../../../icons/LocationIndicator'
import useMapStore from '../../stores/MapStore'
import { useShallow } from 'zustand/react/shallow'

function LocationLayer() {
  const { locationCoordinates, locationIsEnabled } = useMapStore(
    useShallow(state => ({
      locationCoordinates: state.locationCoordinates,
      locationIsEnabled: state.locationIsEnabled
    }))
  )

  if (!locationCoordinates || !locationIsEnabled) {
    return null
  }

  return (
    <Marker longitude={locationCoordinates.longitude} latitude={locationCoordinates.latitude}>
      <LocationIndicator />
    </Marker>
  )
}

export default LocationLayer
