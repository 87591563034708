import * as React from 'react'
import { classes, style } from 'typestyle'
import { Red90, Gray100, typeCompact12Medium, TextItem } from '@traceair/tds'
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns'
import { Schedule } from './types'
import { getDFNSLocale } from '../../../../i18n/i18n'

interface ISchedulesSummaryProps {
  schedule: Schedule
}

const milestoneFlagColors = ['#F68511', '#01CB55', '#E2BE00']

const getFormattedDate = (date: string, isOverdue: boolean) => {
  return `${isOverdue ? '-' : ''}${formatDistanceToNowStrict(new Date(date), {
    locale: getDFNSLocale()
  })}`
}

const SchedulesSummary: React.FC<ISchedulesSummaryProps> = ({ schedule }) => {
  const { milestones } = schedule
  const snapshotMilestones = schedule.latestProgressSnapshot.milestones

  const milestonesMergedWithSnapshot = milestones.map(milestone => {
    const snapshotMilestone = snapshotMilestones.find(snapshotMilestone => snapshotMilestone.milestone === milestone.id)

    return {
      ...milestone,
      isCompleted: snapshotMilestone?.isCompleted.value,
      plannedDoneDate: snapshotMilestone?.plannedDoneDate.value ?? ''
    }
  })

  const formattedMilestones = React.useMemo(() => {
    // check if there are any milestones that are preselected by User
    // if there are no preselected milestones, then select most recent 3 milestones

    const selectedMilestones = milestonesMergedWithSnapshot
      .filter(m => {
        return m !== undefined && m.isKey
      })
      .sort((a, b) => new Date(a.plannedDoneDate).getTime() - new Date(b.plannedDoneDate).getTime())
      .map((milestone, index) => {
        return {
          ...milestone,
          daysLeft: differenceInDays(new Date(milestone.plannedDoneDate), new Date()),
          color: milestoneFlagColors[index % milestoneFlagColors.length]
        }
      })

    if (selectedMilestones.length === 0) {
      return []
    } else {
      return selectedMilestones
    }
  }, [milestonesMergedWithSnapshot])

  if (formattedMilestones.length === 0) {
    return null
  }

  return (
    <div className={containerStyle}>
      <div className={milestonesContainerStyle}>
        {formattedMilestones.map(milestone => (
          <div
            className={classes(
              'schedule-key-milestone',
              milestoneRowStyle,
              milestone.daysLeft < 0 && !milestone.isCompleted && overdueStyle
            )}
            key={milestone.id}
          >
            <div className={milestoneStyle}>
              <div>
                <TextItem
                  text={milestone.name}
                  style={classes('schedule-key-milestone-name', typeCompact12Medium, milestoneNameStyle)}
                />
                <TextItem
                  text={`${format(new Date(milestone.plannedDoneDate), 'MM/dd/yy')}`}
                  style={classes('schedule-key-milestone-done-date', typeCompact12Medium, milestoneDateStyle)}
                />
              </div>
            </div>

            <TextItem
              text={
                milestone.isCompleted
                  ? 'Completed'
                  : getFormattedDate(milestone.plannedDoneDate, milestone.daysLeft < 0)
              }
              style={classes(
                'schedule-key-milestone-status',
                milestone.isCompleted ? 'completed' : 'uncompleted',
                typeCompact12Medium
              )}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(SchedulesSummary)

const containerStyle = style({
  padding: '0 16px'
})

const milestoneDateStyle = style({
  color: Gray100
})

const milestonesContainerStyle = style({
  display: 'flex',
  flexDirection: 'column',
  gap: 8
})

const milestoneRowStyle = style({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  $nest: {
    '&:last-child': {
      marginBottom: 12
    }
  }
})

const overdueStyle = style({
  color: Red90
})

const milestoneNameStyle = style({
  flex: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 170,
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  //@ts-ignore-next-line
  '-webkit-box-orient': 'vertical'
})

const milestoneStyle = style({
  display: 'flex',
  alignItems: 'center',
  gap: 4
})
