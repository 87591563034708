import { InfluxDB } from './Writer'
import { Point } from '@influxdata/influxdb-client-browser'
import AppStore from '../../AppStore'

const metricsConfig: IMetricsConfig = METRICS // Comes from the server

export class TAMetricsWriter {
  private readonly unifiedMetricName: string
  private influxWriter: InfluxDB

  constructor(metricName: string) {
    this.unifiedMetricName = `${metricsConfig.componentName}/${metricName}`
    this.influxWriter = new InfluxDB(metricsConfig.influx.host, false)
  }

  addPoint(point: Point) {
    point.measurement(this.unifiedMetricName)
    TAMetricsWriter.injectCommonTags(point)
    TAMetricsWriter.injectCommonFields(point)
    this.influxWriter.point(point)
  }

  async send() {
    const jwt = AppStore.getState().token

    if (!jwt || !metricsConfig.enabled) {
      return
    }

    this.influxWriter.send(jwt)
  }

  private static injectCommonTags(point: Point) {
    const { user } = AppStore.getState()

    if (!user) return

    point.tag('Environment', metricsConfig.environment)
    point.tag('isTAEmployee', String(/@traceair\.net$/.test(user.email)))
    point.tag('Email', user.email)
  }

  private static injectCommonFields(point: Point) {
    const { user } = AppStore.getState()

    if (!user) return

    point.stringField('Email', user.email)
  }
}
