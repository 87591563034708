import React, { useEffect } from 'react'
import { ButtonsContainer, Select, TextInput, WindowVerticalLayout, Button, SelectOptionType } from '@traceair/tds'
import { i18nKeys } from '../../i18n/i18nkeys'
import { t7e } from '../../i18n/i18n'
import { classes, style } from 'typestyle'
import track from '../../Tracking'
import { getLoginService } from '../../services/LoginService'
import useAppStore from '../../AppStore'
import { getCustomerTypes } from '../../services/CSUApiService'
import ToUButton from './ToUButton'
import SubscriptionManagementAppButton from './SubscriptionManagementAppButton'

interface IUserProfileFormProps {
  onChangePassFormActivate: () => void
}

const UserProfileForm: React.FC<IUserProfileFormProps> = ({ onChangePassFormActivate }) => {
  const user = useAppStore(state => state.user)
  const [editUser, setEditUser] = React.useState(user)
  const [isDone, setIsDone] = React.useState(false)
  const [positionTitles, setPositionTitles] = React.useState<string[]>([])

  useEffect(() => {
    const fetchCustomerTypes = async () => {
      const customerTypes = await getCustomerTypes()
      const companyType = user?.company?.type
      const type = customerTypes.find(ct => ct.key === companyType)

      if (type) {
        setPositionTitles(type.titles)
      }
    }

    fetchCustomerTypes()
  }, [user?.company?.type])

  const positionOptions: SelectOptionType[] = React.useMemo(
    () =>
      positionTitles.map(titleCode => ({ value: titleCode, label: t7e(i18nKeys[titleCode as keyof typeof i18nKeys]) })),
    [positionTitles]
  )

  const handleNameInputChange = (name: string) => {
    if (editUser) {
      setEditUser({ ...editUser, name })
    }
  }

  const handleLastNameInputChange = (lastname: string) => {
    if (editUser) {
      setEditUser({ ...editUser, lastname })
    }
  }

  const handlePhoneInputChange = (phone: string) => {
    if (editUser) {
      setEditUser({ ...editUser, phone })
    }
  }

  const handlePositionSelectChange = (title: string) => {
    if (editUser) {
      setEditUser({ ...editUser, title })
    }
  }

  const updateUser = () => {
    if (editUser) {
      useAppStore.getState().actions.updateUserInfo(editUser)
      setIsDone(true)
      setTimeout(() => {
        setIsDone(false)
      }, 4000)
    }
  }

  const changePassFormActivate = (e?: React.MouseEvent | undefined) => {
    track('Show change password form')

    if (e) {
      e.preventDefault()
    }

    onChangePassFormActivate()
  }

  return (
    <>
      <WindowVerticalLayout>
        <TextInput label={t7e(i18nKeys.ProfileFormEmail)} value={editUser?.email} disabled onChange={() => {}} />
        <TextInput
          label={t7e(i18nKeys.ProfileFormName)}
          value={editUser?.name}
          onChange={name => {
            handleNameInputChange(name)
          }}
          placeholder={t7e(i18nKeys.ProfileFormName)}
        />

        <TextInput
          label={t7e(i18nKeys.ProfileFormLastName)}
          value={editUser?.lastname}
          onChange={lastname => {
            handleLastNameInputChange(lastname)
          }}
          placeholder={t7e(i18nKeys.ProfileFormLastName)}
        />
        <TextInput
          label={t7e(i18nKeys.ProfileFormPhone)}
          value={editUser?.phone}
          onChange={phone => {
            handlePhoneInputChange(phone)
          }}
          placeholder={t7e(i18nKeys.ProfileFormPhone)}
        />
        <Select
          options={positionOptions}
          label={t7e(i18nKeys.ProfileFormPosition)}
          onChange={title => {
            handlePositionSelectChange(title)
          }}
          value={editUser?.title}
          placeholder={t7e(i18nKeys.ProfileFormPosition)}
        />
      </WindowVerticalLayout>
      <ButtonsContainer>
        <SubscriptionManagementAppButton />
        <ToUButton />
      </ButtonsContainer>
      <ButtonsContainer>
        <Button
          className={classes(style({ width: '100%' }))}
          text={t7e(i18nKeys.ProfileFormLogout)}
          onClick={async () => {
            track('Signout Button Click')
            await getLoginService().logout()
            window.location.reload()
          }}
          type='danger'
          style='secondary'
        />
        <Button
          text={t7e(i18nKeys.ProfileFormChangePassword)}
          onClick={e => {
            track('Change Password Button in User Profile Form Click')
            changePassFormActivate(e)
          }}
          style='secondary'
        />
      </ButtonsContainer>
      <ButtonsContainer>
        <Button
          htmlType='submit'
          text={t7e(i18nKeys.ProfileFormSave)}
          done={isDone}
          doneText={t7e(i18nKeys.ProfileFormChangesSaved)}
          onClick={() => {
            track('Save User Changes Button Click')
            updateUser()
          }}
        />
      </ButtonsContainer>
    </>
  )
}

export default UserProfileForm
