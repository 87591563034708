import { Scan } from '../../../../types'
import { formatDistanceToNowStrict } from 'date-fns'
import { getDFNSLocale, t7e } from '../../../../i18n/i18n'
import { i18nKeys } from '../../../../i18n/i18nkeys'

const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y'
}

type FormatOptions = {
  comparison: number
  addSuffix: boolean
}

type KeysFormatDistance = keyof typeof formatDistanceLocale

const formatDistance = <T extends FormatOptions>(token: KeysFormatDistance, count: string, options: T) => {
  options = options || {}
  const result = formatDistanceLocale[token].replace('{{count}}', count)

  if (options.addSuffix) {
    return options.comparison > 0 ? 'in ' + result : result + ' ago'
  }

  return result
}

export const getShortFormattedDataLatestScan = (latestScan?: Scan) => {
  return latestScan
    ? formatDistanceToNowStrict(new Date(latestScan.doneDate), {
        addSuffix: true,
        locale: { ...getDFNSLocale(), formatDistance }
      })
    : t7e(i18nKeys.NoScan)
}
