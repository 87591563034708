import * as React from 'react'
import { AppsIcon } from '@traceair/webapp-icons'
import { Button, Dropdown, INavigationBarButtonProps, NavigationBar } from '@traceair/tds'
import { createUserProfileListItem } from './list_items/UserProfileListItemFactory'
import createUserGuideListItem from './list_items/UserManualListItemFactory'
import createProjectsManagementAppButton from './buttons/ProjectsManagementAppButtonFactory'
import { createBackofficeAppButton } from './buttons/BackofficeButtonFactory'
import createOfficeAppButton from './buttons/OfficeAppButtonFactory'
import { createDashboardButton } from './buttons/DashboardButtonFactory'
import track from '../../../Tracking'
import { IAcl } from '../../../jwt-utils'
import useAppStore from '../../../AppStore'
import { t7e } from '../../../i18n/i18n'
import { i18nKeys } from '../../../i18n/i18nkeys'
import { classes } from 'typestyle'

type AppsNavigationButtonProps = {
  className?: string
}

const AppsNavigationButton: React.FC<AppsNavigationButtonProps> = ({ className }) => {
  const acl = useAppStore(state => state.acl)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const button = renderButton(isOpen, className)

  const listItems = collectListItems()
  const buttons = collectButtons(acl)

  return (
    <Dropdown
      onOpenChange={setIsOpen}
      overlay={<NavigationBar className={'apps-navigation-bar'} listItemGroups={listItems} buttons={buttons} />}
    >
      {button}
    </Dropdown>
  )
}

function renderButton(isOpen: boolean, className?: string) {
  return (
    <Button
      icon={AppsIcon}
      text={t7e(i18nKeys.Menu)}
      type={isOpen ? 'default' : 'black'}
      style='primary'
      onClick={() => {
        track('Apps Navigation Button click')
      }}
      className={classes('navigation-menu-button', className)}
    />
  )
}

function collectListItems() {
  return [[createUserProfileListItem(), createUserGuideListItem()]]
}

function collectButtons(acl: IAcl) {
  return [
    createDashboardButton(),
    createOfficeAppButton(),
    createProjectsManagementAppButton(acl),
    createBackofficeAppButton(acl)
  ].filter(isNotNull)
}

const isNotNull = (b: INavigationBarButtonProps | null): b is INavigationBarButtonProps => b !== null

export default React.memo(AppsNavigationButton)
