import { Widget } from '../../types'

type WidgetsData = {
  primary?: Widget
  carousel: Widget[]
}

export const WIDGETS_MOCKUP_DATA: WidgetsData = {
  primary: {
    link: 'https://try.traceair.net/unlocking-maximum-value-lot-viewer',
    linkParamsFn: ({ name, lastname, email, phone, company }) => ({
      email,
      firstname: name,
      lastname,
      company: company?.name,
      mobilephone: phone
    }),
    type: 'primary',
    mediaPath:
      'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/Lot_Viewer_2024_07.jpg',
    tags: ['Lot Acquisition', 'Grading'],
    header: 'Finish Lots Faster',
    description: 'Precise cut/fill, elevations,\nreports and more',
    mediaStyle: {
      width: 400,
      height: 260
    }
  },
  carousel: [
    {
      link: 'https://try.traceair.net/from-basements-to-roofs-avoiding-costly-setbacks-in-vertical-construction',
      linkParamsFn: ({ email }) => ({
        email
      }),
      type: 'basic-full-height',
      mediaPath:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/Webinar_Vertical_2025_03.jpg',
      tags: ['Webinar', 'Watch Now'],
      header: 'From Basements to Roofs: Avoiding Costly Setbacks\nin Vertical Construction',
      description: 'On Demand',
      mediaStyle: {
        width: 330,
        height: 260
      }
    },
    {
      link: 'https://try.traceair.net/stormwater-report-webinar',
      linkParamsFn: ({ email }) => ({
        email
      }),
      type: 'basic-full-height',
      mediaPath:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/Webinar_Stormwater_2024_12.jpg',
      tags: ['Webinar', 'Watch Now'],
      header: 'Instant Stormwater\nSolutions: Identifying\nSlope and Drainage\nRisks with One Report',
      description: 'On Demand',
      mediaStyle: {
        width: 330,
        height: 260
      }
    },
    {
      link: 'https://www.traceair.net/blog/whats-new/customer-story-ashton-woods',
      linkParamsFn: ({ name, lastname, email, phone, company }) => ({
        email,
        firstname: name,
        lastname,
        company: company?.name,
        mobilephone: phone
      }),
      type: 'basic',
      mediaPath:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/Tech-Forward_Land_Development_11_2024.jpg',
      tags: ['Customer Story'],
      header: 'Tech-Forward Land\nDevelopment',
      description: 'A Q&A with Cole Brooks on Ashton\nWoods’ Success with TraceAir',
      mediaStyle: {
        width: 330,
        height: 260
      }
    },
    {
      link: 'https://www.traceair.net/webinars-on-demand/how-to-achieve-maximum-roi-with-traceair',
      linkParamsFn: ({ name, lastname, email, phone, company }) => ({
        email,
        firstname: name,
        lastname,
        company: company?.name,
        mobilephone: phone
      }),
      type: 'video',
      mediaPath:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/video/Webinar_ROI_2024_08.mp4',
      mediaStyle: {
        filter: 'brightness(0.5)'
      },
      tags: ['Webinar', 'Watch Video'],
      header: 'How to Achieve Maximum\nROI with TraceAir',
      showPlayerIcon: true,
      videoPoster:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/Webinar_ROI_poster_2024_08.jpg'
    },
    {
      link: 'https://www.traceair.net/whats-new/automatic-stockpile-detection',
      linkParamsFn: ({ name, lastname, email, company }) => ({
        email,
        firstname: name,
        lastname,
        company: company?.name
      }),
      type: 'basic',
      mediaPath:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/Stockpile_Detection_2024_08.jpg',
      tags: ['New Feature', 'Stockpiles'],
      header: 'Automatic Stockpile Detection',
      description: 'Fewer Clicks, Less Time, Better Accuracy. Get Started Now!',
      mediaStyle: {
        width: 330,
        height: 260
      }
    },
    {
      link: 'https://www.traceair.net/whats-new/utilizing-technology-to-overcome-stormwater-management-challenges',
      linkParamsFn: ({ name, lastname, email, company }) => ({
        email,
        firstname: name,
        lastname,
        company: company?.name
      }),
      type: 'basic-full-height',
      mediaPath:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/Stormwater_Management_2024_08.jpg',
      tags: ['Blog post', 'Management'],
      header: 'Innovative Solutions in Stormwater Management',
      description: 'Harnessing Technology to Tackle Environmental Challenges',
      mediaStyle: {
        width: 330,
        height: 260
      }
    },
    {
      link: 'https://www.traceair.net/whats-new/lidar-for-land-development-what-it-is-how-it-works-and-why-you-need-it',
      linkParamsFn: ({ name, lastname, email, company }) => ({
        email,
        firstname: name,
        lastname,
        company: company?.name
      }),
      type: 'basic',
      mediaPath:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/LiDar.png',
      tags: ['How it Works', 'Go to Blog'],
      header: 'LiDAR for Land Development',
      description: 'What It Is, How It Works, and\nWhy You Need It - Learn More',
      mediaStyle: {
        width: 330,
        height: 260
      }
    },
    {
      link: 'https://www.traceair.net/whats-new/introducing-exhibits',
      linkParamsFn: ({ name, lastname, email, company }) => ({
        email,
        firstname: name,
        lastname,
        company: company?.name
      }),
      type: 'basic',
      mediaPath:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/Exhibits.png',
      tags: ['How It Works', 'Collaboration'],
      header: 'Get Ahead with Exhibits:\nOptimize Workflows & Collaboration',
      mediaStyle: {
        width: 330,
        height: 260
      }
    },
    {
      link: 'https://www.traceair.net/whats-new/shaun-hofman-mattamy-homes',
      linkParamsFn: ({ name, lastname, email, company }) => ({
        email,
        firstname: name,
        lastname,
        company: company?.name
      }),
      type: 'video',
      mediaPath:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/video/Dashboard_Katie.mp4',
      tags: ['Customer Story', 'Watch Video'],
      header: 'Mattamy Homes Streamlines Earthwork Operations',
      showPlayerIcon: true,
      videoPoster:
        'https://unprotected-traceair.s3.eu-west-1.amazonaws.com/customer_dashboard/widgets/media/images/Mattamy+Homes.png'
    }
  ]
}
