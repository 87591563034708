import React, { useState } from 'react'
import { Alert, Black, Button, ButtonsContainer, WindowVerticalLayout } from '@traceair/tds'
import { AlertIcon } from '@traceair/webapp-icons'
import PasswordInput from './PasswordInput'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import { style } from 'typestyle'
import { getLoginService } from '../../services/LoginService'
import useAppStore from '../../AppStore'
import Throbber from '../Throbber'
import track from '../../Tracking'

interface IChangePassFormProps {
  onChangePassFormDeactivate: () => void
}

const ChangePassForm: React.FC<IChangePassFormProps> = ({ onChangePassFormDeactivate }) => {
  const user = useAppStore(state => state.user)
  const token = useAppStore(state => state.token)
  const [showLengthAlert, setShowLengthAlert] = React.useState(false)
  const [currentPass, setCurrentPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [newPassConfirm, setNewPassConfirm] = useState('')
  const [currPassError, setCurrPassError] = useState<string | null>(null)
  const [confirmPassError, setConfirmPassError] = useState<string | null>(null)
  const [newPassError, setNewPassError] = useState<string | null>(null)
  const [pending, setPending] = useState(false)
  const [success, setSuccess] = useState(false)

  const handlePasswordUpdateSuccess = () => {
    setSuccess(true)
    setPending(false)
    setTimeout(() => {
      onChangePassFormDeactivate()
    }, 4000)
  }

  const handlePasswordUpdateFailed = () => {
    setCurrPassError(t7e(i18nKeys.WrongEnteredCurrentPassError))
    setPending(false)
    setSuccess(false)
  }

  const isValid = async () => {
    refreshFields()

    try {
      if (user && token) {
        await getLoginService().changePassword(token, user.email, currentPass, newPass, newPassConfirm)
        handlePasswordUpdateSuccess()
      }
    } catch (err) {
      handlePasswordUpdateFailed()
    }

    if (newPass !== newPassConfirm) {
      setConfirmPassError(t7e(i18nKeys.NewPassDoNotMatchError))
    }

    if (newPass.length < 8) {
      setNewPassError(t7e(i18nKeys.NewPassLengthError))
    }

    if (!newPass) {
      setNewPassError(t7e(i18nKeys.NewPassRequiredError))
    }

    if (!newPassConfirm) {
      setConfirmPassError(t7e(i18nKeys.NoEnteredNewPassError))
    }

    if (!currentPass) {
      setCurrPassError(t7e(i18nKeys.CurrentPassRequired))
    }
  }

  const refreshFields = () => {
    setCurrPassError(null)
    setConfirmPassError(null)
    setNewPassError(null)
  }

  const updatePassword = () => {
    setPending(true)
    isValid()
  }

  return (
    <Throbber show={pending}>
      <WindowVerticalLayout>
        <div className={marginBottom}>
          <PasswordInput
            onChange={currentPass => {
              setCurrentPass(currentPass)
            }}
            value={currentPass}
            placeholder={t7e(i18nKeys.ProfileFormChangePassword)}
            title={t7e(i18nKeys.ProfileFormChangePassword)}
            errorMessage={currPassError ? currPassError : undefined}
            isInvalid={Boolean(currPassError)}
            shouldBeFocused={true}
          />
        </div>
        {showLengthAlert && (
          <Alert icon={<AlertIcon color={Black} />} type='neutral' title={t7e(i18nKeys.NewPassLengthError)} />
        )}
        <PasswordInput
          onChange={newPass => {
            setNewPass(newPass)
          }}
          onFocus={() => {
            setShowLengthAlert(true)
          }}
          onBlur={() => {
            setShowLengthAlert(false)
          }}
          value={newPass}
          placeholder={t7e(i18nKeys.NewPassHint)}
          title={t7e(i18nKeys.NewPassHint)}
          isInvalid={Boolean(newPassError)}
          errorMessage={newPassError ? newPassError : undefined}
        />
        <PasswordInput
          onChange={newPassConfirm => {
            setNewPassConfirm(newPassConfirm)
          }}
          value={newPassConfirm}
          placeholder={t7e(i18nKeys.NewPassReEnterHint)}
          title={t7e(i18nKeys.NewPassReEnterHint)}
          isInvalid={Boolean(confirmPassError)}
          errorMessage={confirmPassError ? confirmPassError : undefined}
        />
      </WindowVerticalLayout>
      <ButtonsContainer>
        <Button
          htmlType='submit'
          text={t7e(i18nKeys.SavePassword)}
          done={success}
          doneText={t7e(i18nKeys.ChangesSaved)}
          onClick={() => {
            track('Save Password Button Click')
            updatePassword()
          }}
        />
      </ButtonsContainer>
    </Throbber>
  )
}

export default ChangePassForm

const marginBottom = style({
  marginBottom: 8,
  // disable bootstrap error message margin
  $nest: {
    '& > div > div > label': {
      marginBottom: '0px !important'
    }
  }
})
