import { useEffect, useRef, useState } from 'react'
import { Position } from 'geojson'

const ANIMATION_DURATION = 200

export function useAnimationMarker(toCoordinates: Position, fromCoordinates?: Position) {
  const [coordinates, setCoordinates] = useState({
    longitude: fromCoordinates?.[0] || toCoordinates[0],
    latitude: fromCoordinates?.[1] || toCoordinates[1]
  })

  const startTimestamp = useRef<number | null>(null)

  useEffect(() => {
    let animationFrameId: number

    const animateMarker = (timestamp: number) => {
      if (!fromCoordinates || !toCoordinates) return

      if (!startTimestamp.current) startTimestamp.current = timestamp
      const progress = Math.min((timestamp - startTimestamp.current) / ANIMATION_DURATION, 1)

      const newLongitude = fromCoordinates[0] + (toCoordinates[0] - fromCoordinates[0]) * progress
      const newLatitude = fromCoordinates[1] + (toCoordinates[1] - fromCoordinates[1]) * progress

      setCoordinates({ longitude: newLongitude, latitude: newLatitude })

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(animateMarker)
      }
    }

    animationFrameId = requestAnimationFrame(animateMarker)

    return () => cancelAnimationFrame(animationFrameId)
  }, [fromCoordinates, toCoordinates])

  return { coordinates }
}
