import React from 'react'
import { classes, style } from 'typestyle'
import { Blue80, Gray100, typeCompact14Medium, TextItem, typeCompact10Medium } from '@traceair/tds'
import CircleProgress from './schedule/CircleProgress'
import { Schedule } from './schedule/types'
import { format, parseISO } from 'date-fns'
import Highlighter from 'react-highlight-words'
import useAppStore from '../../../AppStore'
import track from '../../../Tracking'

interface SiteCardTitleProps {
  siteName: string
  customerName: string
  schedule?: Schedule
  officeAppLink: string
}

const SiteCardTitle: React.FC<SiteCardTitleProps> = ({ siteName, customerName, schedule, officeAppLink }) => {
  const siteNameFilterString = useAppStore(state => state.siteNameFilterString)

  return (
    <div className={classes('site-card-title-container', containerStyle)}>
      <div className={classes('site-card-title', titleStyle)}>
        <div
          className={classes('site-name', typeCompact14Medium, siteNameStyle)}
          onClick={() => {
            track('Scan Name clicked')
            window.open(officeAppLink, '_blank')
          }}
        >
          <Highlighter
            highlightClassName={style({ backgroundColor: 'transparent', color: Blue80 })}
            searchWords={[siteNameFilterString]}
            textToHighlight={siteName}
          />
        </div>
        <div className={classes('site-card-subtitle', subtitleStyle)}>
          <TextItem text={customerName} style={classes('customer-name', typeCompact10Medium)} />
          {schedule?.latestProgressSnapshot?.snapshotDate && <div className={classes('dot-spacer', dotSpacerStyle)} />}
          {schedule?.latestProgressSnapshot?.snapshotDate && (
            <TextItem
              text={formatSnapshotDate(schedule?.latestProgressSnapshot?.snapshotDate)}
              style={classes('schedule-last-update-date', typeCompact10Medium)}
            />
          )}
        </div>
      </div>
      {schedule?.latestProgressSnapshot?.progress !== undefined ? (
        <CircleProgress value={schedule.latestProgressSnapshot.progress} />
      ) : null}
    </div>
  )
}

export default SiteCardTitle

const formatSnapshotDate = (snapshotDate: string) => {
  const date = parseISO(snapshotDate)

  return `Updated ${format(date, 'MMM d, yyyy')}`
}

const containerStyle = style({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 16px 0 16px',
  marginTop: 4
})

const titleStyle = style({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
})

const siteNameStyle = style({
  cursor: 'pointer',
  lineHeight: '22.4px',
  fontSize: 16,
  marginBottom: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  //@ts-ignore-next-line
  '-webkit-box-orient': 'vertical'
})

const dotSpacerStyle = style({
  width: 2,
  height: 2,
  borderRadius: 10,
  backgroundColor: Gray100,
  margin: '2px 4px 0 4px'
})

const subtitleStyle = style({
  display: 'flex',
  color: Gray100,
  flexWrap: 'wrap',
  alignItems: 'center'
})
