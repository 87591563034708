import { GeoJSONSource, LayerProps, MapGeoJSONFeature } from 'react-map-gl/maplibre'
import type { MapRef } from 'react-map-gl/maplibre'
import { Markers } from './MarkersLayer'
import { Cluster } from '../../stores/MapStore'
import { LngLat, LngLatBounds } from 'maplibre-gl'
import { POINTS_IN_CLUSTER_AGGRESSIVE_EXPANSION_THRESHOLD } from '../constants'

interface Clusters {
  features: MapGeoJSONFeature[]
  clusters: MapGeoJSONFeature[]
}

type GeoJSONPoint = GeoJSON.Feature<GeoJSON.Point>

export class MarkersLayerManager {
  static layerId = 'cluster-layer-id'
  static sourceId = 'cluster-source-id'

  clusterMaxZoom = 9
  clusterRadius = 80

  source: GeoJSONSource
  map: MapRef

  constructor(private mapRef: MapRef) {
    this.map = mapRef
    this.source = mapRef.getSource(MarkersLayerManager.sourceId) as GeoJSONSource

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.__mLM = this
  }

  getLayer(): LayerProps {
    return {
      id: MarkersLayerManager.layerId,
      type: 'circle',
      source: MarkersLayerManager.sourceId,
      filter: ['has', 'point_count'],
      paint: { 'circle-radius': 0 }
    }
  }

  getSource() {
    return {
      id: MarkersLayerManager.sourceId,
      cluster: true,
      clusterMaxZoom: this.clusterMaxZoom,
      clusterRadius: this.clusterRadius
    }
  }

  async updateMarkers(previousMarkers: Markers): Promise<Markers> {
    if (!this.source) {
      return { clusters: [], points: [] }
    }

    const markers = await this.calculateMarkers(previousMarkers)
    const clusters = await this.createClusters(markers.clusters)
    const points = this.createPoints(markers.features)

    return { clusters, points }
  }

  private async calculateOptimalZoom(clusterId: number): Promise<number> {
    const baseZoom = await this.source.getClusterExpansionZoom(clusterId)
    const leaves = (await this.source.getClusterLeaves(clusterId, Infinity, 0)) as MapGeoJSONFeature[]

    // If few points, use default expansion
    if (leaves.length <= POINTS_IN_CLUSTER_AGGRESSIVE_EXPANSION_THRESHOLD) {
      return baseZoom
    }

    // For larger clusters, expand more aggressively
    // Add more zoom levels for larger clusters
    const extraZoom = Math.min(2, Math.log(leaves.length) / 1.1)

    return Math.min(baseZoom + extraZoom, this.clusterMaxZoom)
  }

  private async createClusters(clusters: MapGeoJSONFeature[]): Promise<Cluster[]> {
    const clusterPromises = clusters.map(async feature => {
      const expansionZoom = await this.calculateOptimalZoom(feature.properties.cluster_id)
      const coordinates = (feature as GeoJSONPoint).geometry.coordinates
      const center = new LngLat(coordinates[0], coordinates[1])

      return {
        expansionZoom,
        center,
        pointCount: feature.properties.point_count,
        coordinates,
        id: feature.id || feature.properties.cluster_id,
        children: feature.properties.children,
        parentCoordinates: feature.properties.parentCoordinates
      }
    })

    return Promise.all(clusterPromises)
  }

  private createPoints(features: MapGeoJSONFeature[]) {
    return features.map(feature => ({
      coordinates: (feature as GeoJSONPoint).geometry.coordinates,
      properties: feature.properties
    }))
  }

  private async calculateMarkers(previousMarkers: Markers): Promise<Clusters> {
    const unclusteredFeatures = this.map.querySourceFeatures(MarkersLayerManager.sourceId, {
      filter: ['!=', 'cluster', true]
    })

    const clusters = this.map.querySourceFeatures(MarkersLayerManager.sourceId, {
      filter: ['==', 'cluster', true]
    })

    // there are might be duplicated clusters due to iternal maplibre reasons, so return unique ones only
    // @see https://maplibre.org/maplibre-gl-js/docs/API/classes/Map/#querysourcefeatures
    const clustersMap: Record<string, MapGeoJSONFeature> = {}

    clusters.forEach(ac => (clustersMap[ac.properties.cluster_id] = ac))
    const uniqueClusters = Object.values(clustersMap)

    const unclusteredFeaturesMap: Record<string, MapGeoJSONFeature> = {}

    unclusteredFeatures.forEach(ac => (unclusteredFeaturesMap[ac.properties.uniqueId] = ac))
    const uniqueUnclusteredFeaturesMap = Object.values(unclusteredFeaturesMap)

    for (const cluster of uniqueClusters) {
      const children = await this.source.getClusterChildren(cluster.properties.cluster_id)

      cluster.properties.children = children.map(c => c.properties!.cluster_id || c.properties!.id)
      cluster.properties.parentCoordinates = this.getParentCoordinates(cluster, previousMarkers.clusters)
    }

    return { features: uniqueUnclusteredFeaturesMap, clusters: uniqueClusters }
  }

  async handleClick(center: LngLat, expansionZoom: number, clusterId: number) {
    this.map.flyTo({
      center,
      zoom: expansionZoom
    })

    // Get all leaves of the cluster
    const leaves = (await this.source.getClusterLeaves(clusterId, Infinity, 0)) as MapGeoJSONFeature[]

    // Calculate bounds for all points
    const bounds = new LngLatBounds()

    leaves.forEach(leaf => {
      const [lng, lat] = (leaf as GeoJSONPoint).geometry.coordinates

      bounds.extend([lng, lat])
    })

    // Fit to bounds with some padding
    this.map.fitBounds(bounds, {
      padding: 100,
      duration: 1000
    })
  }

  private getParentCoordinates(cluster: MapGeoJSONFeature, previousClusters?: Cluster[]) {
    if (!cluster.id) {
      return
    }

    // if a `previousCluster` matches the current cluster, return `parentCoordinates` from previous cluster
    const previousCluster = previousClusters?.find(prevCluster => prevCluster.id === cluster.id) as Cluster

    if (previousCluster?.parentCoordinates) {
      return previousCluster.parentCoordinates
    }

    // If the cluster is not the same (e.g. when we zoom in), find its parent by searching in `previousClusters`' children
    const parentCluster = previousClusters?.find(prevCluster => {
      return prevCluster.children.includes(cluster.id as number)
    })

    if (parentCluster) {
      return parentCluster.coordinates
    }
  }
}
