import { memo } from 'react'
import { classes, stylesheet } from 'typestyle'
import { Badge, Purple70, White } from '@traceair/tds'

interface SideCardBadgesProps {
  formattedDate: string
  lotViewerLabel: string
  isLotViewerEnabled?: boolean
  className?: string
}

const SiteCardBadges = memo(function SiteCardBadges({
  formattedDate,
  isLotViewerEnabled,
  lotViewerLabel,
  className
}: SideCardBadgesProps) {
  return (
    <div className={className}>
      <Badge className={classes('scan-date-publish-day-badge', css.badge)} type='neutral-black' label={formattedDate} />
      {isLotViewerEnabled && (
        <Badge
          className={classes('scan-preview-lot-viewer-badge', css.lotViewerBadge, css.badge)}
          label={lotViewerLabel}
        />
      )}
    </div>
  )
})

export default SiteCardBadges

const css = stylesheet({
  badge: {
    backdropFilter: 'none !important' // disable backdrop filter for badges because it causes performance issues on map
  },

  lotViewerBadge: {
    $nest: {
      '&.scan-preview-lot-viewer-badge': {
        background: Purple70,
        color: White
      }
    }
  }
})
