import { classes, style } from 'typestyle'
import SitesMap from './SitesMap'
import Sidebar from '../sidebar/Sidebar'
import { BaseLayerModeSwitcher } from './components/base_layer_mode_switcher/BaseLayerModeSwitcher'

type MapModeContainerProps = {
  sidebarOpen: boolean
  onVisibilityChanged: (value: boolean) => void
}

export function MapModeContainer({ sidebarOpen, onVisibilityChanged }: MapModeContainerProps) {
  return (
    <div className={classes('dashboard-map-container', containerMapStyle)}>
      <SitesMap />
      <div className={classes(containerSidebarStyle, !sidebarOpen && containerSidebarCollapsedStyle)}>
        <Sidebar
          onVisibilityChanged={onVisibilityChanged}
          isCollapsed={!sidebarOpen}
          collapseToButtonOnTablet={false}
        />
        <BaseLayerModeSwitcher isSidebarCollapsed={!sidebarOpen} />
      </div>
    </div>
  )
}

const containerMapStyle = style({
  position: 'relative',
  height: '100%'
})

const containerSidebarStyle = style({
  display: 'flex',
  gap: 24,

  position: 'absolute',
  top: 24,
  bottom: 24,
  left: 24
})

const containerSidebarCollapsedStyle = style({
  left: 0
})
