import * as React from 'react'
import { classes, media, style } from 'typestyle'
import NavigationButtons from './NavigationButtons'
import { headerHeight } from '../../layout-constants'
import LogoIcon from '../../icons/LogoIcon'
import { Breakpoint } from '../../responsive-breakpoints'

const Header = () => {
  return (
    <div className={classes(headerStyle, 'dashboard-header-container')}>
      <LogoIcon />
      <NavigationButtons />
    </div>
  )
}

export default Header

const headerStyle = style(
  {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 22px 0 24px',
    height: headerHeight,
    justifyContent: 'space-between'
  },
  media({ maxWidth: Breakpoint.MOBILE }, { padding: '0 10px 0 16px' })
)
