import * as React from 'react'
import { Black, Blue80, Gray10, List, ListItem, typeCompact12Medium } from '@traceair/tds'
import { FolderEmptyIcon, FolderIcon } from '@traceair/webapp-icons'
import { classes, style } from 'typestyle'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import useAppStore, { AppStoreType, CustomerIdFilter } from '../../AppStore'
import track from '../../Tracking'
import { getSitesCountText, getSortedCustomers } from './helpers'
import { CustomerWithStatistic } from '../../types'
import { useShallow } from 'zustand/react/shallow'
import NoSitesFound from '../NoSitesFound'
import { useWindowWidth } from '../../hooks'
import { Breakpoint } from '../../responsive-breakpoints'

function filterCustomersBySiteFilterString(customers: CustomerWithStatistic[], siteNameFilterString: string) {
  if (!siteNameFilterString) {
    return customers
  }

  const siteNameFilterStringLowerCase = siteNameFilterString.toLowerCase()

  return customers
    .map(customer => {
      const filteredSites = customer.sites.filter(site =>
        site.name.toLowerCase().includes(siteNameFilterStringLowerCase)
      )

      return { ...customer, sites: filteredSites }
    })
    .filter(customer => customer.sites.length > 0)
}

const getItemDescription = (count: number, showOnlyFiltered: boolean) => {
  return (
    <span className={classes('customer-sites-count', typeCompact12Medium, showOnlyFiltered && foundSitesCounterStyle)}>
      {getSitesCountText(count, showOnlyFiltered)}
    </span>
  )
}

interface ICustomersListProps {
  onSidebarVisibilityChanged: (value: boolean) => void
}

const CustomersList: React.FunctionComponent<ICustomersListProps> = ({ onSidebarVisibilityChanged }) => {
  const { customersWithStatistic, customerIdFilter, siteNameFilterString, actions } = useAppStore(
    useShallow((state: AppStoreType) => ({
      customersWithStatistic: state.customersWithStatistic,
      siteNameFilterString: state.siteNameFilterString,
      customerIdFilter: state.customerIdFilter,
      actions: state.actions
    }))
  )

  const windowWidth = useWindowWidth()
  const isTabletOrMobile = windowWidth <= Breakpoint.TABLET

  const sortedCustomers: CustomerWithStatistic[] = React.useMemo(() => {
    return getSortedCustomers<CustomerWithStatistic>(
      filterCustomersBySiteFilterString(customersWithStatistic, siteNameFilterString)
    )
  }, [customersWithStatistic, siteNameFilterString])
  const allProjectsCount = React.useMemo<number>(() => {
    return sortedCustomers.reduce((acc, cur) => {
      return acc + (cur.totalSites !== undefined ? cur.totalSites : 0)
    }, 0)
  }, [sortedCustomers])

  const handleCustomerListItemClick = (customerIdFilter: CustomerIdFilter) => {
    track('Customer Filter clicked')
    actions.setCustomerFilterId(customerIdFilter)
    isTabletOrMobile && onSidebarVisibilityChanged(false)
  }

  const showOnlyFiltered = siteNameFilterString.length > 0

  if (sortedCustomers.length === 0) {
    return <NoSitesFound showHint={false} />
  }

  return (
    <List rounded>
      {sortedCustomers.length > 1 && (
        <ListItem
          title={t7e(i18nKeys.AllProjects)}
          description={getItemDescription(allProjectsCount, showOnlyFiltered)}
          className={classes('customer-list-item', listItemStyle)}
          selected={customerIdFilter === null}
          leftCell={customerIdFilter === null ? <FolderIcon /> : <FolderEmptyIcon />}
          onClick={() => handleCustomerListItemClick(null)}
        />
      )}
      {sortedCustomers.map(customer => (
        <ListItem
          title={customer.name}
          description={getItemDescription(customer.totalSites, showOnlyFiltered)}
          key={customer.id}
          className={classes('customer-list-item', listItemStyle)}
          selected={customerIdFilter === customer.id}
          leftCell={customerIdFilter === customer.id ? <FolderIcon /> : <FolderEmptyIcon />}
          onClick={() => handleCustomerListItemClick(customer.id)}
        />
      ))}
    </List>
  )
}

const listItemStyle = style({
  $nest: {
    '& .tds-list-item-text-title > *': {
      lineHeight: '16px',
      fontWeight: 500
    },
    '&.selected': {
      borderColor: 'initial',
      background: Gray10,
      borderRadius: 12
    },
    '&.selected *': {
      color: Black
    },
    '&.selected .ta-list-item-wrapper-for-border': {
      boxShadow: 'none !important'
    }
  }
})

const foundSitesCounterStyle = style({
  color: `${Blue80} !important`
})

export default CustomersList
