import { Schedule } from './components/sites_grid/site_card/schedule/types'
import { NestedCSSProperties } from 'typestyle/lib/types'
import { ICurrentUser } from './jwt-utils'

export type CustomerId = string

export enum ProjectActivity {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  All = 'ALL'
}

export type SiteId = string

export type Site = {
  id: SiteId
  customerId: CustomerId
  name: string
  projectActivity: ProjectActivity
  latestScan?: Scan
  latestPanos: Pano[]
  schedule: Schedule
  lotViewer?: boolean
}

export type ShortSiteData = {
  id: SiteId
  name: string
  projectActivity: ProjectActivity
  coordinates: [number, number] | null
}

export type CustomerData = {
  id: CustomerId
  name: string
  sites: ShortSiteData[]
}

export type CustomerWithStatistic = CustomerData & {
  totalSites: number
  withSchedules: number
  withPastDueMilestones: number
  withLotViewer: number
}

export type Scan = {
  doneDate: string
  previewPath?: string
}

export type Pano = {
  doneDate: string
  path: string
  customerId: CustomerId
  siteId: SiteId
  previewPath: string
}

export type SiteData = {
  siteId: SiteId
  customerId: CustomerId
  name: string
  projectActivity: ProjectActivity
  latestScan?: Scan
  latestPanos: Pano[]
  schedule?: Schedule
  hasLotViewer: boolean
}

export type Filters = {
  active: boolean
  withSchedule: boolean
  withLotViewer: boolean
  pastDueMilestones: boolean
  archived: boolean
}

export enum SortingOrders {
  SiteName = 'siteName',
  ScanDate = 'scanDate',
  ScheduleUpdated = 'scheduleUpdated',
  ScheduleProgress = 'scheduleProgress'
}

export enum SortingDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Sorting = {
  key: SortingOrders
  direction: SortingDirection
}

export type WidgetTagType = string | { isPrimary: boolean; text: string }

export type Widget = {
  link: string
  linkParamsFn?: (userData: ICurrentUser) => Record<string, string | undefined>
  type: 'primary' | 'basic' | 'basic-full-height' | 'video'
  mediaPath: string
  showPlayerIcon?: boolean
  tags: WidgetTagType[]
  header: string
  description?: string
  mediaStyle?: NestedCSSProperties
  videoPoster?: string
}

export enum BaseLayerMode {
  Satellite = 'SATELLITE',
  OSM = 'OSM'
}
