import useMapStore, { LocationCoordinates } from '../../stores/MapStore'
import { MapRef } from 'react-map-gl/maplibre'
import { MapMouseEvent } from 'react-map-gl'

export class LocationLayerManager {
  private map?: MapRef
  private subscribeLocationCoordinates?: () => void
  private subscribeLocationError?: () => void

  public subscribeToStore() {
    this.subscribeLocationCoordinates = useMapStore.subscribe(
      state => state.locationCoordinates,
      this.handleLocationCoordinatesChange
    )
    this.subscribeLocationError = useMapStore.subscribe(state => state.locationError, this.handleLocationError)
  }

  public unsubscribeFromStore() {
    if (this.subscribeLocationCoordinates) {
      this.subscribeLocationCoordinates()
    }

    if (this.subscribeLocationError) {
      this.subscribeLocationError()
    }

    this.map?.off('movestart', this.handleMapMove)
  }

  private handleLocationCoordinatesChange = (locationCoordinates: LocationCoordinates | null) => {
    if (!locationCoordinates) {
      return
    }

    this.flyToLocationMarker()
  }

  private handleLocationError() {
    useMapStore.getState().actions.setLocationIsEnabled(false)
    useMapStore.getState().actions.setLocationCoordinates(null)
    this?.map?.off('movestart', this.handleMapMove)
  }

  private handleMapMove = (e: MapMouseEvent & { locationUpdateMove?: boolean }) => {
    if (e.locationUpdateMove) {
      return
    }

    useMapStore.getState().actions.setLocationNeedToFly(true)
  }

  public flyToLocationMarker() {
    useMapStore.getState().actions.setLocationNeedToFly(false)
    const locationCoordinates = useMapStore.getState().locationCoordinates

    if (locationCoordinates) {
      const lng = locationCoordinates.longitude
      const lat = locationCoordinates.latitude

      this.map?.flyTo({ center: { lng, lat } }, { locationUpdateMove: true })
    }

    this.map?.on('movestart', this.handleMapMove)
  }

  public handleLocationIsEnabledChange() {
    useMapStore.getState().actions.setLocationIsEnabled(true)
  }

  public handleHideLocation() {
    useMapStore.getState().actions.setLocationIsEnabled(false)
    useMapStore.getState().actions.setLocationNeedToFly(false)
  }

  public setMap(map: MapRef) {
    this.map = map
  }
}

export const locationManager = new LocationLayerManager()
