import * as React from 'react'
import { Gray100, Search } from '@traceair/tds'
import { t7e } from '../../i18n/i18n'
import { i18nKeys } from '../../i18n/i18nkeys'
import track from '../../Tracking'
import useAppStore from '../../AppStore'
import { style } from 'typestyle'

type SearchProjectsInputProps = {
  siteNameFilterString: string
}

const SearchProjectsInput: React.FunctionComponent<SearchProjectsInputProps> = ({
  siteNameFilterString
}: SearchProjectsInputProps) => {
  const { setSiteNameFilterString } = useAppStore.getState().actions

  const handleSearchStringChange = (value: string) => {
    track('Project Search')
    setSiteNameFilterString(value)
  }

  const handleSearchStringClear = () => {
    track('Project Search cleared')
    setSiteNameFilterString('')
  }

  return (
    <div style={{ padding: '8px 16px 12px' }}>
      <Search
        placeholder={t7e(i18nKeys.SearchProject)}
        onChange={handleSearchStringChange}
        onClear={handleSearchStringClear}
        value={siteNameFilterString}
        className={searchInputStyle}
      />
    </div>
  )
}

export default SearchProjectsInput

const searchInputStyle = style({
  $nest: {
    '&::-webkit-input-placeholder': {
      color: Gray100
    }
  }
})
