import { classes, style } from 'typestyle'
import { useMap } from 'react-map-gl/maplibre'
import { CONTROLS_MARGIN_RIGHT, Z_INDICES } from './constants'
import BaseLayer from './layers/base_layer/BaseLayer'
import MarkersLayer from './layers/markers_layer/MarkersLayer'
import ZoomControl from './components/ZoomControl'
import { useHandleMapZoomControlChange, useHandleSidebarVisibilityChange, useMapFit } from './hooks'
import { LocationControl } from './components/location_control/LocationControl'
import LocationLayer from './layers/location_layer/LocationLayer'

export function SitesMapContent() {
  const { current: mapRef } = useMap()

  useHandleMapZoomControlChange(mapRef)
  useHandleSidebarVisibilityChange(mapRef)
  useMapFit(mapRef)

  return (
    <>
      <BaseLayer />
      {mapRef && <MarkersLayer map={mapRef} />}
      <LocationLayer />
      <div className={classes('map-right-controls', rightControlsStyle)}>
        <ZoomControl />
        <LocationControl map={mapRef} />
      </div>
    </>
  )
}

const rightControlsStyle = style({
  position: 'absolute',
  right: CONTROLS_MARGIN_RIGHT,
  bottom: '45%',

  zIndex: Z_INDICES.EXPANDED_CARD
})
